import React from 'react';
import ErrorPage from './ErrorPage';
import NoEncryptionOutlinedIcon from '@mui/icons-material/NoEncryptionOutlined';

/**
 * Renders a page if the user is unauthorized
 * for that particular feature.
 *
 * @returns { Element } The layout of the unauthorized page
 */
export default function UnauthorizedPage() {
	return (
		<ErrorPage
			icon={<NoEncryptionOutlinedIcon sx={{ fontSize: '9rem' }} />}
			code="401"
			name="Unauthorized Access"
			message={
				<span>
					The page you&apos;re looking for has restricted access. <br />
					Please contact your administrator.
				</span>
			}
		/>
	);
}
