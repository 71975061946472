import BaseService from './base.service';

class ReportService extends BaseService {
	/**
	 * Submit reports to the server.
	 *
	 * @param { String } accommId The ID of reported accommodation
	 * @param { String } userId   The ID of the reporter
	 * @param { Object } report   Details about the report
	 */
	async submitReport(accommId, ownerId, userId, report) {
		try {
			const { data } = await this.axios.post('/api/report/', {
				accommodationId: accommId,
				accomOwnerId: ownerId,
				userId,
				description: report,
			});

			console.log(data);

			return data;
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * Retrieve  data of all reports from the server.
	 *
	 * @returns { Promise<{ reportDetails: Array | undefined }> } An array of reports
	 */
	async viewReports() {
		try {
			const res = await this.axios.get('/api/report/');
			const { data } = res.data;
			return data;
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * Update the details of a report
	 *
	 * @param { String } reportId   The ID of the certain report from the admin page
	 *
	 * @returns { string } Result of updating the report
	 */
	async updateReport(reportId, newStatus) {
		try {
			const res = await this.axios.patch(`/api/report/${reportId}`, {
				statusUpdate: newStatus, // string, "resolved" or "rejected"
			});
			const { data } = res;
			return data;
		} catch (err) {
			console.log(err);
			return err;
		}
	}
}

export default ReportService;
