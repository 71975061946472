import React from "react";
import { Form, Formik } from "formik";
import StepHeader from "./StepHeader";
import StepButton from "../button/StepButton";
import { PropTypes } from "prop-types";

/**
 * Serves as a wrapper to a form to enable
 * multistep features.
 *
 * @param { Array } children          Contains the sub-forms (or steps) of the form
 * @param { Array } sectionNames      The names of each step in the form
 * @param { Object } initialValues    The placeholder values in a form
 * @param { func } handleSubmit       A handler after the user submitted the form
 *
 * @returns { Formik }  A Formik object to handle form validation
 */
const Wizard = ({ children, sectionNames, initialValues, handleSubmit }) => {
  const [stepIndex, setStepIndex] = React.useState(0);
  const [snapshot, setSnapshot] = React.useState(initialValues);

  const steps = React.Children.toArray(children);
  const fieldset = steps[stepIndex];

  /**
   * Proceeds to the next section or step
   * of the form.
   *
   * @param { Object } values The current state of the form
   */
  const gotoNext = (values) => {
    setSnapshot(values);
    setStepIndex(Math.min(stepIndex + 1, steps.length - 1));
  };

  /**
   * Goes back to the previous section or
   * step of the form.
   *
   * @param { Object } values The current state of the form
   */
  const gotoBack = (values) => {
    setSnapshot(values);
    setStepIndex(Math.max(stepIndex - 1, 0));
  };

  /**
   * Handles the user's answers of the form
   * after the last step.
   *
   * @param { Object } values The current state of the form
   * @param { FormikBag } bag An object containing form data and handlers
   */
  const submit = async (values, bag) => {
    if (stepIndex === steps.length - 1) return handleSubmit(values, bag);
    else {
      bag.setTouched({});
      gotoNext(values);
    }
  };

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={submit}
      validationSchema={fieldset.props.validationSchema}
    >
      {(formik) => (
        <Form>
          {/* Step Header */}
          <StepHeader sectionNames={sectionNames} activeStep={stepIndex} />

          {/* Fields */}
          {fieldset}

          {/* Step Button (Bottom Menu) */}
          <StepButton
            activeStep={stepIndex}
            stepCount={steps.length}
            isProceed={formik.isSubmitting}
            handleBack={() => gotoBack(formik.values)}
          />
        </Form>
      )}
    </Formik>
  );
};

Wizard.propTypes = {
  children: PropTypes.element,
  sectionNames: PropTypes.array,
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
};

/**
 * Serves as a wrapper for a form section.
 *
 * @param { Element } children        The form section component
 * @param { Object } validationSchema The validator of the section (must be included)
 *
 * @returns { Element } The rendered form section
 */
const WizardStep = ({ children }) => children;

export { Wizard, WizardStep };
