import React from "react";
import { PropTypes } from "prop-types";

const WizardContext = React.createContext();

/**
 * `WizardProvider` handles the state of the overall form,
 * specifically for multistep ones.
 *
 * @param { JSX } children  The multistep form as a JSX component
 *
 * @returns { WizardContext.Provider }  A ready-to-use wrapper in handling the form state
 */
const WizardProvider = ({ children }) => {
  const [wizardState, setWizardState] = React.useState({});
  return (
    <WizardContext.Provider value={[wizardState, setWizardState]}>
      {children}
    </WizardContext.Provider>
  );
};

WizardProvider.propTypes = {
  children: PropTypes.element,
};

export default WizardProvider;
