import React, { useState } from "react";
import FilterList from "@mui/icons-material/FilterList";
import { FilterButtonTheme } from "../../context/ButtonContext";
import { IconButton, ThemeProvider, Menu } from "@mui/material";
import FilterCard from "../FilterCard";
import { PropTypes } from "prop-types";
import { FILTER_CHOICES } from "../../utils/constants";

/**
 * Creates a button to display filtering options for the data in the catalog
 *
 * @param { Array } value List of filters applied
 * @param { func } onChange Executes something after an input is changed
 *
 * @returns { IconButton }  A white button with red border and a filter icon as its label
 */

export default function FilterButton({ value, onChange }) {
  const filterChoices = FILTER_CHOICES;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={FilterButtonTheme}>
      <IconButton
        sx={{
          height: "44px",
          width: "44px",
          color: open === false ? "primary.main" : "secondary.main",
          borderRadius: 0,
          border: "2px solid",
          backgroundColor: open === false ? "secondary.main" : "primary.main",
          borderColor: "primary.main",
          ":hover":
            open === false
              ? { bgcolor: "primary.main", color: "secondary.main" }
              : { bgcolor: "secondary.main", color: "primary.main" },
        }}
        onClick={handleClick}
      >
        <FilterList />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          maxHeight: "390px",
        }}
      >
        {filterChoices.map((element, index) => (
          <FilterCard
            key={index}
            title={element.title}
            choices={element.choices}
            value={value}
            onChange={onChange}
          />
        ))}
      </Menu>
    </ThemeProvider>
  );
}

FilterButton.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};
