import React from 'react';
import { PropTypes } from 'prop-types';
import { Select, MenuItem } from '@mui/material';

/**
 * Creates a button to display filtering options for the data in the catalog
 *
 * @param { func } handleChange Executes something after sort option is changed
 *
 * @returns { Select } List of sorting options
 */

export default function SortButton({ handleChange }) {
	return (
		<Select
			defaultValue="-1"
			sx={{
				width: 200,
				height: 44,
			}}
			onChange={(event) => handleChange(event.target.value)}
		>
			<MenuItem value={-1}>Price: Low to High</MenuItem>
			<MenuItem value={1}>Price: High to Low</MenuItem>
			{/* <MenuItem value={3}>Rating: Low to High</MenuItem>
      <MenuItem value={4}>Rating: High to Low</MenuItem> */}
		</Select>
	);
}

SortButton.propTypes = {
	handleChange: PropTypes.func,
};
