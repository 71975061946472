import React from "react";
import { PropTypes } from "prop-types";
import Remove from "@mui/icons-material/Remove";
import { RemoveButtonTheme } from "../../context/ButtonContext";
import { IconButton, ThemeProvider } from "@mui/material";

/**
 * Removes a particular value from a set of values or
 * decrements a property by a certain value.
 *
 * @param { func } handleClick  Handler when the button is clicked
 *
 * @returns { IconButton }  A red button with a minus (-) sign as its label
 */
export default function RemoveButton({ handleClick }) {
  return (
    <ThemeProvider theme={RemoveButtonTheme}>
      <IconButton
        sx={{
          height: "44px",
          width: "44px",
          color: "primary.main",
          bgcolor: "secondary.main",
          ":hover": { bgcolor: "primary.main", color: "white" },
          ":disabled": { bgcolor: "#e0e0e0", color: "#a6a6a6" },
        }}
        onClick={handleClick}
      >
        <Remove />
      </IconButton>
    </ThemeProvider>
  );
}

RemoveButton.propTypes = {
  handleClick: PropTypes.func,
};
