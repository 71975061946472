import React from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";
import PropTypes from "prop-types";

/**
 * Renders a modified text field.
 *
 * @param { String } name         Name of the involved field
 * @param { String } label        A display name (instead of the name)
 * @param { bool } disabled       Indicates if the text field is disabled (Default: FALSE)
 * @param { String } placeholder  Helper text for the text field (optional)
 * @param { any } overrides       Properties that can be overriden in the component
 *
 * @returns { TextField } The modified text field with the properties
 */
export default function InputField({
  name,
  label,
  disabled = false,
  placeholder = undefined,
  overrides,
}) {
  // Handle validation states in Formik.
  const [field, meta] = useField(name);
  const { touched, error } = meta;
  const isError = touched && error;

  return (
    <TextField
      type="text"
      error={isError}
      id={name}
      placeholder={placeholder || label}
      helperText={isError && error}
      disabled={disabled}
      fullWidth
      {...overrides}
      {...field}
    />
  );
}

InputField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  overrides: PropTypes.object,
};
