import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

/**
 * Serves as the general page for rendering
 * an error in routing.
 *
 * @param { Icon } icon       Icon describing the error
 * @param { Element } code    An element of a styled error code
 * @param { String } name     The name of the error
 * @param { String } message  Description about the error
 *
 * @returns { Element } The layout of
 */
export default function ErrorPage({ icon, code, name, message }) {
	const navigate = useNavigate();

	return (
		<Container
			display="flex"
			sx={{
				mb: '8rem',
				mt: '4rem',
				textAlign: 'center',
			}}
		>
			<Grid container direction="column" justify="center" alignItems="center">
				{/* Start of Error Code */}
				<Typography
					color="primary"
					sx={{
						fontSize: '10rem',
						display: 'flex',
						alignItems: 'center',
						fontWeight: 600,
						mb: '-1.2rem',
					}}
				>
					{code[0]}
					{icon}
					{code[2]}
				</Typography>
				{/* End of Error Code */}

				<Box>
					{/* Start of Error Name */}
					<Typography variant="h3">{name}</Typography>
					{/* End of Error Name */}

					{/* Start of Error Description */}
					<Typography
						color="var(--gray)"
						sx={{ mb: '4rem', mt: '1rem', fontSize: '1.4rem' }}
					>
						{message}
					</Typography>
					{/* End of Error Description */}
				</Box>

				{/* Start of Return Button */}
				<Button
					variant="outlined"
					color="primary"
					onClick={() => navigate('/')}
					sx={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}
				>
					<ArrowBackIcon />
					RETURN TO HOME
				</Button>
				{/* End of Return Button */}
			</Grid>
		</Container>
	);
}

ErrorPage.propTypes = {
	icon: PropTypes.element,
	code: PropTypes.element,
	name: PropTypes.string,
	message: PropTypes.string,
};
