import React from "react";
import { Box, Typography } from "@mui/material";
import { PropTypes } from "prop-types";
import InputField from "./fields/InputField";

/**
 *  This component renders a label and text input field for a form.
 *
 * @param { string } name - The name of the input field.
 * @param { string } label - The label for the text input field.
 * @param { string } placeholder - The placeholder text for the text input field.
 * @param { string } type - The type of the text input field, e.g. "text", "email", "password", "number".
 * @param { boolean } multiline - Whether the text input field should allow multiple lines of input.
 * @param { number } rows - The number of rows to display in the text input field, if multiline is true.
 *
 * @returns { FormTextField } A JSX element that displays the text input field for the form.
 */

export default function FormTextField({
  name,
  label,
  placeholder,
  type,
  multiline,
  rows,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {/* Label */}
      <Typography variant="h6" sx={{ minWidth: 180 }}>
        {label}
      </Typography>

      {/* Text Field */}
      <InputField
        name={name}
        label={label}
        placeholder={placeholder}
        multiline={multiline}
        type={type}
        rows={rows}
        fullWidth={true}
      />
    </Box>
  );
}

FormTextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
};
