import React from 'react';
import Template from './Template';
import useAuth from '../../hooks/useAuth';
import ReportService from '../../services/report.service';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, CircularProgress } from '@mui/material';
import EmptyMessage from '../../components/common/EmptyMessage';
import UserService from '../../services/user.service';
import ApplyCard from '../../components/ApplyCard';
import ReportCard from '../../components/ReportCard';

/**
 * Displays the reports and applications for
 * accommodation owners.
 *
 * @returns { Element } The profile page of the admin
 */
export default function AdminProfile() {
	const { auth } = useAuth();

	const [reportDetails, setReportDetails] = React.useState([]);
	const [applications, setApplications] = React.useState([]);
	const [isFetching, setFetchingStatus] = React.useState(false);

	// Handle the change of tabs.
	const [tab, setTab] = React.useState(1);
	const handleChange = (event, newTab) => {
		setTab(newTab);
	};

	// Get the details of each report.
	const getReports = async () => {
		const service = new ReportService();
		setFetchingStatus(true); // Display Loading Screen
		try {
			const res = await service.viewReports();
			if (res === undefined) throw new Error();
			setReportDetails(res);
			setFetchingStatus(false); // Remove Loading Screen
		} catch (err) {
			console.log(err);
			setFetchingStatus(false); // Remove Loading Screen
		}
	};

	// Get the details of each owner application.
	const getApplications = async () => {
		const service = new UserService();
		setFetchingStatus(true); // Display Loading Screen
		try {
			const res = await service.viewAllApplications();
			if (res === undefined) throw new Error();
			setApplications(res);
			setFetchingStatus(false); // Remove Loading Screen
		} catch (err) {
			console.log(err);
			setFetchingStatus(false); // Remove Loading Screen
		}
	};

	React.useEffect(() => {
		getReports();
		getApplications();
	}, []);

	return (
		<Template
			user={{
				...auth,
				fullName: {
					firstName: auth.firstName,
					lastName: auth.lastName,
				},
			}}
		>
			{/* Start of Tabs */}
			<TabContext value={tab}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleChange}>
						<Tab label="Reports" value={1} />
						<Tab label="Owner Applications" value={2} />
					</TabList>
				</Box>

				{/* Start of Reports */}
				<TabPanel value={1}>
					{isFetching ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<CircularProgress
								size={40}
								sx={{ color: 'gray' }}
								style={{ paddingTop: '100px', paddingBottom: '100px' }}
							/>
						</div>
					) : reportDetails.length > 0 ? (
						<Box sx={{ mt: '1rem' }}>
							{reportDetails.map((report, i) => {
								return <ReportCard key={i} report={report} />;
							})}
						</Box>
					) : (
						<EmptyMessage
							topPadding={8}
							bottomPadding={8}
							dataType={'reports'}
						/>
					)}
				</TabPanel>
				{/* End of Reports */}

				{/* Start of Owner Applications */}
				<TabPanel value={2}>
					{isFetching ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<CircularProgress
								size={40}
								sx={{ color: 'gray' }}
								style={{ paddingTop: '100px', paddingBottom: '100px' }}
							/>
						</div>
					) : applications.length > 0 ? (
						<Box sx={{ mt: '1rem' }}>
							{applications.map((application, i) => {
								return <ApplyCard key={i} application={application} />;
							})}
						</Box>
					) : (
						<EmptyMessage
							topPadding={8}
							bottomPadding={8}
							dataType={'owner applications'}
						/>
					)}
				</TabPanel>
				{/* End of Owner Applications */}
			</TabContext>
			{/* End of Tabs */}
		</Template>
	);
}
