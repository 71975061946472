import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ReportService from '../services/report.service';
import toast from 'react-hot-toast';
import { REPORT_STATUS } from '../utils/constants';
import { Paper, Box, Typography, IconButton } from '@mui/material';

/**
 * Renders a card containing details
 * about the report.
 *
 * @returns { Element } A ready-to-use card containg a report
 */
export default function ReportCard({ report }) {
	/**
	 * Handle the acceptance of an application.
	 *
	 * @param { String } reportID   The ID of the report
	 */
	const handleAccept = async (reportID) => {
		const service = new ReportService();
		try {
			const res = await service.updateReport(reportID, REPORT_STATUS.RESOLVED);
			if (res.success) {
				toast.success(res.message, { duration: 3000 });
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			} else {
				toast.error(res.message, { duration: 5000 });
			}
			return res;
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * Handle the rejection of an application.
	 *
	 * @param { String } reportID   The ID of the report
	 */
	const handleReject = async (reportID) => {
		const service = new ReportService();
		try {
			const res = await service.rejectApplication(
				reportID,
				REPORT_STATUS.REJECTED
			);
			if (res.success) {
				toast.success(res.message, { duration: 3000 });
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			} else {
				toast.error(res.message, { duration: 5000 });
			}
			return res;
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Paper elevation={3} sx={{ p: '2rem', width: '60%' }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				{/* Start of Information */}
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '0.25rem',
					}}
				>
					{/* Name */}
					<Typography variant="body1">
						<b>Reported by:</b> User ID{' '}
						<b style={{ color: 'var(--red)' }}>{report.userId}</b> <br />
						<b>Accommodation ID:</b>{' '}
						<b style={{ color: 'var(--red)' }}>{report.accommodationId}</b>{' '}
						<br />
						<b>Accommodation Owner ID:</b>{' '}
						<b style={{ color: 'var(--red)' }}>{report.accomOwnerId}</b>
					</Typography>

					{/* Status */}
					<Typography variant="body1" sx={{ fontWeight: 600, mt: '0.5rem' }}>
						Status:{' '}
						<span style={{ color: 'var(--red)' }}>
							{report.status.toUpperCase()}
						</span>
					</Typography>
				</Box>
				{/* End of Information */}

				{/* Start of Check/Cross */}
				{report.status !== 'pending' || (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							gap: '0rem',
						}}
					>
						<IconButton
							sx={{ height: '2rem' }}
							onClick={() => handleAccept(report._id)}
						>
							<CheckCircleOutlineOutlinedIcon
								sx={{ fontSize: '2rem', color: '#4d7c0f' }}
							/>
						</IconButton>

						<IconButton
							sx={{ height: '2rem' }}
							onClick={() => handleReject(report._id)}
						>
							<CancelOutlinedIcon sx={{ fontSize: '2rem', color: '#b91c1c' }} />
						</IconButton>
					</Box>
				)}
				{/* End of Check/Cross */}
			</Box>
		</Paper>
	);
}

ReportCard.propTypes = {
	report: PropTypes.object,
};
