import React from "react";
import { Stepper, Step, StepLabel } from "@mui/material";
import { PropTypes } from "prop-types";

/**
 * Maps the section names in the form to their respective
 * steps in the component to make it a multistep form.
 *
 * @param { Array } sectionNames  The names of each step in the form
 * @param { int } activeStep      The index of the section being viewed currently
 *
 * @returns { Stepper } A `Stepper` component with the mapped steps
 */
export default function StepHeader({ sectionNames, activeStep }) {
  return (
    <Stepper activeStep={activeStep} alternativeLabel sx={{ width: "100%" }}>
      {sectionNames.map((name) => (
        <Step key={name}>
          <StepLabel>{name}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

StepHeader.propTypes = {
  sectionNames: PropTypes.array,
  activeStep: PropTypes.number,
};
