import React from "react";
import AuthContext from "../context/AuthContext";

/**
 * Serves as a convenient hook to handle saving
 * or accessing the context of user authentication.
 *
 * @returns { AuthContext } Context involving user authentication
 */
export default function useAuth() {
  return React.useContext(AuthContext);
}
