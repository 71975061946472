import React, { useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import { useFormikContext } from 'formik';
import FormPreview from '../../../components/form/FormPreview';
import PropTypes from 'prop-types';

/**
 * Renders a layout of the user's inputs
 * in the fields.
 *
 * @returns { Grid }  Preview containing the inputs to every field
 */
export default function Preview({ files }) {
	const { values } = useFormikContext();

	// From BasicInformation.jsx
	// TODO: Implement preview of pictures
	const thumbs = files.map((file) => (
		<Box
			sx={{
				display: 'inline-flex',
				width: '30%',
				height: 'auto',
				boxSizing: 'border-box',
				border: 'solid gray 1px',
				borderRadius: '5px',
				margin: '5px',
			}}
			key={file.name}
		>
			<Box
				sx={{
					display: 'flex',
					minWidth: 0,
					overflow: 'hidden',
					padding: '5px',
				}}
			>
				<img
					src={file.preview}
					style={{
						display: 'block',
						maxWidth: '100%',
						height: 'auto',
					}}
					// Revoke data uri after image is loaded
					onLoad={() => {
						URL.revokeObjectURL(file.preview);
					}}
				/>
			</Box>
		</Box>
	));

	useEffect(() => {
		// Make sure to revoke the data uris to avoid memory leaks, will run on unmount
		return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
	}, []);

	return (
		<React.Fragment>
			{/* Start of Space Allowance */}
			<Box sx={{ mt: 8 }}></Box>
			{/* End of Space Allowance */}

			<Grid container spacing={4}>
				{/* Name */}
				<Grid item xs={6}>
					<FormPreview label="Name" value={values.accommodationName} />
				</Grid>

				{/* Type */}
				<Grid item xs={6}>
					<FormPreview label="Type" value={values.type} />
				</Grid>

				{/* Description */}
				<Grid item xs={12}>
					<FormPreview label="Description" value={values.description} />
				</Grid>

				{/* Address - Amenities */}
				<Grid item xs={6}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '32px',
						}}
					>
						{/* Address */}
						<FormPreview label="Address" value={values.completeAddress} />

						{/* Price Range */}
						<FormPreview
							label="Price Range"
							value={`${values.minPrice} - ${values.maxPrice}`}
						/>

						{/* Tenants */}
						<FormPreview label="Tenants" value={values.tenants} />

						{/* TODO: Reflect input on preview. */}
						{/* Bill Inclusions */}
						<FormPreview
							label="Bill Inclusions"
							value={values.billInclusions.join(', ')}
						/>

						{/* Amenities */}
						<FormPreview
							label="Amenities"
							value={values.amenities.join(', ')}
						/>
					</Box>
				</Grid>

				{/* Town - Visitors */}
				<Grid item xs={6}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '32px',
						}}
					>
						{/* Town */}
						<FormPreview label="Town" value={values.location} />

						{/* Minimum Stay */}
						<FormPreview label="Minimum Stay" value={values.minimumStay} />

						{/* Capacity */}
						<FormPreview label="Capacity" value={values.pax} />

						{/* Curfew */}
						<FormPreview
							label="Curfew"
							value={values.curfew.toISOString().substring(11, 16)}
						/>

						{/* Visitors */}
						<FormPreview label="Visitors" value={values.visitorsAllowed} />
					</Box>
				</Grid>

				{/* Thumbnail */}
				<Box
					sx={{
						display: 'inline-flex',
						flexDirection: 'row',
						flexWrap: 'wrap',
						width: '100%',
					}}
				>
					{thumbs}
				</Box>
			</Grid>

			{/* Start of Space Allowance */}
			<Box sx={{ mb: 8 }}></Box>
			{/* End of Space Allowance */}
		</React.Fragment>
	);
}

Preview.propTypes = {
	files: PropTypes.arr,
};
