import React from "react";
import { PropTypes } from "prop-types";
import FilterButton from "../button/FilterButton";
import SortButton from "../button/SortButton";
import { Typography, Stack, Chip, Box } from "@mui/material";
// import { Star } from "@mui/icons-material";

/**
 * Creates an accommodation header that is composed of the following;
 * Title - Accommodations
 * FilterButton - Filters the accommodations shown
 * SortButton - Sorts the accommodations shown
 *
 * @param { string } title title of header
 * @param { array } filterState array of applied filters
 * @param { func } handleChangeFilter function from page that gets the filters
 * @param { func } handleChangeSort function from page that gets the sorting option
 * @param { func } handleDeleteFilter function from page that removes filter using the chip
 *
 * @returns { AccommodationHeader } A header found above the accommodation cards
 */
export default function AccommodationHeader({
  title,
  filterState,
  handleChangeFilter,
  handleChangeSort,
  handleDeleteFilter,
}) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="h4" color="primary">
        {title}
      </Typography>
      <Box
        sx={{
          position: "flex",
          width: 600,
        }}
      >
        {filterState.map((element) => (
          <Chip
            key={element}
            label={element}
            variant="outlined"
            sx={{ margin: 0.5 }}
            onDelete={(event) => handleDeleteFilter(event, element)}
          />
        ))}
      </Box>
      <Stack direction="row" alignItems="center" gap={2}>
        <FilterButton value={filterState} onChange={handleChangeFilter} />
        <SortButton handleChange={handleChangeSort} />
      </Stack>
    </Stack>
  );
}

AccommodationHeader.propTypes = {
  title: PropTypes.string,
  filterState: PropTypes.array,
  handleChangeFilter: PropTypes.func,
  handleChangeSort: PropTypes.func,
  handleDeleteFilter: PropTypes.func,
};
