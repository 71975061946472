import React from "react";
import { PropTypes } from "prop-types";
import Add from "@mui/icons-material/Add";
import { AddButtonTheme } from "../../context/ButtonContext";
import { IconButton, ThemeProvider } from "@mui/material";

/**
 * Adds a particular value to a set of values or
 * increments a property by a value.
 *
 * @param { func } handleClick  Handler when the button is clicked
 *
 * @returns { IconButton }  A green button with a plus (+) sign as its label
 */
export default function AddButton({ handleClick }) {
  return (
    <ThemeProvider theme={AddButtonTheme}>
      <IconButton
        sx={{
          height: "44px",
          width: "44px",
          color: "primary.main",
          bgcolor: "secondary.main",
          ":hover": { bgcolor: "primary.main", color: "white" },
          ":disabled": { bgcolor: "#e0e0e0", color: "#a6a6a6" },
        }}
        onClick={handleClick}
      >
        <Add />
      </IconButton>
    </ThemeProvider>
  );
}

AddButton.propTypes = {
  handleClick: PropTypes.func,
};
