import React from 'react';
import { Notifications } from '@mui/icons-material';
import PropTypes from 'prop-types';
import {
	Button,
	Popover,
	Typography,
	Box,
	Badge,
	Divider,
} from '@mui/material';

/**
 * Displays a list of notifications that are
 * reports from other users.
 *
 * @param { Array } notifList An array of NotifItems (notifications)
 *
 * @returns { Popover } A pop-up containing the notifications
 */
export default function NotifPopover({ notifList }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	/**
	 * Displays the popover once the bell icon
	 * is clicked.
	 *
	 * @param { Event } event
	 */
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	/**
	 * Closes the popover once the screen (or
	 * outside) is clicked.
	 */
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			{/* Start of Notif Icon */}
			<Button sx={{ borderRadius: '50px' }} onClick={handleClick}>
				<Badge badgeContent="" color="primary" variant="dot" overlap="circular">
					<Notifications sx={{ color: 'var(--black)' }} />
				</Badge>
			</Button>
			{/* End of Notif Icon */}

			{/* Start of Popover */}
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				sx={{ borderRadius: '1rem' }}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<Box sx={{ px: '2rem', py: '2rem' }}>
					{/* Start of Header */}
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '4rem',
						}}
					>
						<Typography variant="h5" color="primary">
							Notifications
						</Typography>

						<Button
							variant="text"
							sx={{
								textDecoration: 'none',
								color: 'var(--gray)',
								fontWeight: 700,
							}}
						>
							View More
						</Button>
					</Box>
					{/* End of Header */}

					<Divider light sx={{ margin: '1rem auto' }} />

					{/* Start of Notif List */}
					<Box sx={{ display: 'flex', flexDirection: 'column', mt: '0.8rem' }}>
						{notifList || (
							<Typography
								variant="body1"
								sx={{
									color: 'var(--gray)',
									textAlign: 'center',
									margin: '1.25rem auto 1rem',
								}}
							>
								No notifications to show.
							</Typography>
						)}
					</Box>
					{/* End of Notif List */}
				</Box>
			</Popover>
			{/* End of Popover */}
		</React.Fragment>
	);
}

NotifPopover.propTypes = {
	notifList: PropTypes.array,
};
