import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

/**
 * Serves as the connector for unauthorized routing.
 *
 * If the user goes to a non-existent/unauthorized page, their
 * respective routes will display.
 *
 * @param { Array } allowedRoles  The roles allowed for a certain route
 *
 * @returns { Element } The page to be redirected based on their role
 */
export default function RequireAuth({ allowedRoles }) {
	const location = useLocation();
	const { auth } = useAuth();

	return [auth.role]?.find((role) => allowedRoles?.includes(role)) ? (
		<Outlet />
	) : auth.id ? (
		<Navigate to="/unauthorized" state={{ fom: location }} replace />
	) : (
		<Navigate to="/" state={{ fom: location }} replace />
	);
}

RequireAuth.propTypes = {
	allowedRoles: PropTypes.array,
};
