import * as yup from 'yup';
import {
	RequiredError,
	TypeError,
	EmptyNumberError,
} from '../errors/schema.errors';
import { validateFileSize, validateFileType } from '../helpers';
import { FILE_SIZE_FUNCNAME, FILE_TYPE_FUNCNAME } from '../constants';

// Add helper methods to validate files.
yup.addMethod(yup.mixed, FILE_SIZE_FUNCNAME, validateFileSize);
yup.addMethod(yup.mixed, FILE_TYPE_FUNCNAME, validateFileType);

/**
 * Involves basic information about the
 * accommodation place.
 *
 * @returns { Object }  Schema of the basic information section
 */
// TODO: Integrate the thumbnail field.
const basicInfoSchema = yup.object().shape({
	accommodationName: yup.string().required(RequiredError('name')),
	type: yup.string().required(RequiredError('type')),
	description: yup.string().required(RequiredError('description')),
	completeAddress: yup.string().required(RequiredError('address')),
	location: yup.string().required(RequiredError('town')),
	minPrice: yup
		.number()
		.typeError(TypeError('number'))
		.required(RequiredError('minimum price'))
		.min(1, EmptyNumberError()),
	maxPrice: yup
		.number()
		.typeError(TypeError('number'))
		.required(RequiredError('maximum price'))
		.min(1, EmptyNumberError())
		.moreThan(
			yup.ref('minPrice'),
			'This field should be greater than the minimum price.'
		),
	minimumStay: yup
		.number()
		.typeError(TypeError('number'))
		.required(RequiredError('minimum stay'))
		.min(1, EmptyNumberError()),
	tenants: yup.string().required(RequiredError('tenants')),
	pax: yup
		.number()
		.typeError(TypeError('number'))
		.required(RequiredError('capacity'))
		.min(1, EmptyNumberError()),
	thumbnail: yup.mixed().required(RequiredError),
});

/**
 * Involves additional information, such as
 * amenities and other minor matters.
 *
 * @returns { Object }  Schema of the additional information section
 */
const addInfoSchema = yup.object().shape({
	billInclusions: yup.array().of(yup.string()),
	amenities: yup.array().of(yup.string()),
	curfew: yup.string().required(RequiredError('curfew')),
	visitorsAllowed: yup.string().required(RequiredError('visitors')),
});

/**
 * Contains the initial values of the form.
 *
 * @returns { Object }  Contains the initial values
 */
const initialValues = {
	accommodationName: '',
	type: '',
	description: '',
	completeAddress: '',
	location: '',
	minPrice: '',
	maxPrice: '',
	minimumStay: '',
	tenants: '',
	pax: 0,
	thumbnail: null,

	billInclusions: [],
	amenities: [],

	curfew: '',
	visitorsAllowed: '',
};

export { basicInfoSchema, addInfoSchema, initialValues };
