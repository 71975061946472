import React from 'react';
import { Box } from '@mui/material';
import ReportService from '../../services/report.service';
import TemplateModal from './common/Template';
import PropTypes from 'prop-types';
import ReportIcon from '@mui/icons-material/Report';
import FieldContainer from '../form/FieldContainer';
import InputField from '../form/fields/InputField';
import { Form, Formik } from 'formik';
import { reportSchema, initialValues } from '../../utils/schema/report.schema';
import ModalFooter from './common/ModalFooter';
import useAuth from '../../hooks/useAuth';
import toast from 'react-hot-toast';

/**
 * This contains the modal where the user can
 * submit a report.
 *
 * @returns { Modal } A ready-to-use modal with the report form
 */
export default function ReportModal({
	openState,
	handleClose,
	accommId,
	accomOwnerId,
}) {
	const { auth } = useAuth();

	/**
	 * Submit a report to the server.
	 *
	 * @param { Object } report The report relating to the accommodation
	 */
	const submitReport = async (report) => {
		const reportService = new ReportService();
		console.log('== REPORT ==');
		console.dir(report);

		try {
			const res = await reportService.submitReport(
				accommId,
				accomOwnerId,
				auth.id,
				report
			);

			// Display an alert/toast.
			if (res.success) toast.success(res.message, { duration: 3000 });
			else toast.error(res.message, { duration: 5000 });
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<TemplateModal
			icon={<ReportIcon />}
			title="Submit Report"
			openState={openState}
			handleClose={handleClose}
		>
			<Formik
				initialValues={initialValues}
				validationSchema={reportSchema}
				onSubmit={(values) => {
					submitReport({
						...values,
						userId: auth.id,
						accommodationId: accommId,
						accomOwnerId: accomOwnerId,
					});
					handleClose();
				}}
			>
				{(formik) => {
					return (
						<Form>
							{/* Start of Report Field */}
							<Box sx={{ px: '2rem', py: '1.25rem' }}>
								<FieldContainer
									label="Care to share more?"
									component={
										<InputField
											name="description"
											label="Description"
											placeholder="Share details about your report"
											rows={6}
										/>
									}
								/>
							</Box>
							{/* End of Report Field */}

							{/* Start of Footer */}
							<ModalFooter
								label="Submit"
								disabled={formik.isSubmitting}
								handleSuccess={formik.submitForm}
								handleClose={handleClose}
							/>
							{/* End of Footer */}
						</Form>
					);
				}}
			</Formik>
		</TemplateModal>
	);
}

ReportModal.propTypes = {
	openState: PropTypes.bool,
	handleClose: PropTypes.func,
	accommId: PropTypes.string,
	accomOwnerId: PropTypes.string,
};
