import BaseService from './base.service';
import useAuth from '../hooks/useAuth';
import { saveAs } from 'file-saver';

class FavoriteService extends BaseService {
	/**
	 * Get a list of favorites (accommodation places)
	 * from the server.
	 *
	 * @param { String } userID   The current ID of the user
	 * @param { String } accomID  The ID of the desired accommodation
	 *
	 * @returns { Promise<{ accommDetails: Array | undefined }> } An array of IDs of accommodations
	 */
	async addFavorite(userID, accomID) {
		try {
			const res = await this.axios({
				method: 'post',
				url: '/api/user/favorite',
				data: {
					userID: userID.toString(),
					accomID: accomID.toString(),
				},
			});

			const { data } = res;
			const { auth, setAuth } = useAuth();
			setAuth({
				...auth,
				favorites: [...auth.favorites, accomID],
			});

			return data;
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * Generate a PDF file of (accommodation places)
	 * from the server stored as a PDF file.
	 *
	 * @param { String } userId Identifier of the user's data
	 *
	 * @returns { PDF } A PDF file containing a list of accommodations
	 */
	async generateFavorites(userId) {
		try {
			await this.axios({
				method: 'post',
				url: `/api/user/catalogue/${userId}`,
				responseType: 'blob',
				headers: {
					Accept: 'application/pdf',
				},
			}).then((res) => {
				var blob = new Blob([res.data], {
					type: 'application/pdf',
				});

				saveAs(blob, 'Catalog.pdf');
			});
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * Retrieve previews of data of all favorite accomodations
	 * from the server.
	 */
	async getFavorites(userId) {
		try {
			const res = await this.axios.get(`api/user/favorite/${userId}`);
			return res.data;
		} catch (err) {
			console.log(err);
		}
	}
}

export default FavoriteService;
