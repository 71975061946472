import BaseService from './base.service';
import { FILTER_CHOICES } from '../utils/constants';

class AccommService extends BaseService {
	/**
	 * Retrieve previews of data of all accommodation
	 * from the server.
	 * @param { string } search search value for the accommodation name
	 * @param { array } filters array of filters applied
	 * @returns { Promise<{ accommDetails: Array | undefined }> } An array of accommodations
	 */
	async viewAllAccommodation(search, filters, sort, pageNum) {
		try {
			let page = `page=${pageNum}`;
			let type = 'type=';
			let price = 'price=';
			let loc = 'loc=';
			let tenant = 'tenant=';
			let accomName =
				search === undefined
					? 'accomodationName='
					: 'accommodationName=' + search;

			filters.map((element) => {
				if (FILTER_CHOICES[0]['choices'].includes(element)) {
					if (element == 'Hotel') type += '0,';
					else if (element == 'Apartment') type += '1,';
					else if (element == 'Bed Space') type += '2,';
					else if (element == 'Dormitory') type += '3,';
					else if (element == 'Transient') type += '4,';
				} else if (FILTER_CHOICES[1]['choices'].includes(element)) {
					if (element == 'Under Php 2,000') price += '0,';
					else if (element == 'Php 2,000 - Php 4,000') price += '1,';
					else if (element == 'Php 4,000 - Php 6,000') price += '2,';
					else if (element == 'Above Php 6,000') price += '3,';
				} else if (FILTER_CHOICES[2]['choices'].includes(element)) {
					loc += element + ',';
				} else if (FILTER_CHOICES[3]['choices'].includes(element)) {
					if (element == 'Males Only') tenant += '0,';
					else if (element == 'Females Only') tenant += '1,';
					else if (element == 'Coed') tenant += '2,';
				}
			});

			type = type.endsWith(',') ? type.slice(0, -1) : type;
			price = price.endsWith(',') ? price.slice(0, -1) : price;
			loc = loc.endsWith(',') ? loc.slice(0, -1) : loc;
			tenant = tenant.endsWith(',') ? tenant.slice(0, -1) : tenant;

			let final = '/api/accom/?';
			if (accomName !== 'accommodationName=') final += accomName + '&';
			if (type !== 'type=') final += type + '&';
			if (price !== 'price=') final += price + '&';
			if (loc !== 'loc=') final += loc + '&';
			if (tenant !== 'tenant=') final += tenant + '&';
			final += page;

			final += `&order=${sort || 1}`;

			final.slice(0, -1);
			console.log(`🔗 Link: ${final}`);
			const res = await this.axios.get(final);

			const { data } = res.data;
			console.dir(data);
			return data;
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * Retrieve data of a specific accommodation from the server.
	 *
	 * @param {string} accommId - The ID of the accommodation.
	 *
	 * @returns { AxiosResponse } Indicates if the request is successful or not.
	 */
	async viewAccommodation(accommId) {
		try {
			console.log(accommId);
			const res = await this.axios.get(`/api/accom/${accommId}`);
			const { data } = res.data;
			return data;
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * Delete an accommodation based on id
	 *
	 * @param { String } accomId  The id of the accommodation to be archived
	 *
	 * @returns { AxiosResponse } Indicates if the request is successful or not
	 */
	async deleteAccommodation(accomId) {
		try {
			const res = await this.axios.delete(`/api/accom/delete/${accomId}`);
			const { data } = res;
			return data;
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * Archive an accommodation based on id
	 *
	 * @param { String } accomId  The id of the accommodation to be archived
	 *
	 * @returns { AxiosResponse } Indicates if the request is successful or not
	 */
	async archiveAccommodation(accomId) {
		try {
			const res = await this.axios.put(`/api/accom/archive/${accomId}`);
			const { data } = res;
			return data;
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * Adds an accommodation to the server.
	 *
	 * @param { Object } accom - The accommodation to be added
	 *
	 * @returns { AxiosResponse } Indicates if the request is successful or not
	 */
	async addAccommodation(accom) {
		try {
			const res = await this.axios.post('/api/accom/add', accom, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			const { data } = res;
			return data;
		} catch (err) {
			console.log(err);
		}
	}
}

export default AccommService;
