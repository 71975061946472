import React from "react";
import { Box, Typography } from "@mui/material";
import { PropTypes } from "prop-types";

/**
 * This component renders a label and value pair for previewing form data.
 *
 * @param { string } label - The label for the form field.
 * @param { string } value - The value of the form data.
 *
 * @returns { FormPreview } A JSX element that displays the label and value for the form data.
 */

export default function FormPreview({ label, value }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" sx={{ minWidth: 180 }}>
        {label}
      </Typography>
      <Typography variant="p" sx={{ flexGrow: 1 }}>
        {value}
      </Typography>
    </Box>
  );
}

FormPreview.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};
