import { createTheme } from '@mui/material/styles';

/**
 * This provides the styles defined in the design system.
 * Apply the theme to MUI components only.
 *
 * 1) To apply the theme, import this object in your file.
 * 2) Then, wrap the MUI component around a `ThemeProvider` object.
 * 3) Finally, set the `theme` property to the `theme` object,
 * which was the one you imported.
 *
 * @returns { Theme } A ready-to-use theme object.
 */
export const theme = createTheme({
	palette: {
		primary: {
			light: '#FE8A7B',
			main: '#FE7968',
			dark: '#FE6653',
			contrastText: '#FEFEFE',
		},

		secondary: {
			light: '#78C9AD',
			main: '#68C3A3',
			dark: '#5BBE9B',
			contrastText: '#FEFEFE',
		},

		neutral: {
			main: '#707070',
		},
	},

	typography: {
		fontFamily: ['Outfit'].join(','),
		h1: {
			fontWeight: 600,
		},

		h2: {
			fontWeight: 600,
		},

		h3: {
			fontWeight: 600,
		},

		h4: {
			fontWeight: 600,
		},

		h5: {
			fontWeight: 600,
		},

		h6: {
			fontWeight: 600,
			textTransform: 'uppercase',
		},

		button: {
			fontWeight: 700,
		},
	},
});
