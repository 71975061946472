import React from 'react';
import PropTypes from 'prop-types';
import { Box, Pagination } from '@mui/material';

/**
 * Renders a pagination cmponent to
 * move in between entries.
 *
 * @param { Integer } pages         Total number of pages
 * @param { func } handleChangePage Goes back/next a page
 *
 * @returns { Element } A Pagination compnent
 */
export default function Page({ pages, handleChangePage }) {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			<Pagination
				count={pages}
				variant="outlined"
				onChange={handleChangePage}
				sx={{ margin: 'auto' }}
			/>
		</Box>
	);
}

Page.propTypes = {
	pages: PropTypes.number,
	handleChangePage: PropTypes.func,
};
