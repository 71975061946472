import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { PropTypes } from 'prop-types';
import Pagination from '@mui/material/Pagination';
import AccommodationHeader from '../components/common/AccommodationHeader';
import CarouselCards from '../components/common/CarouselCards';
import AccommService from '../services/accommodation.service';
import queryString from 'query-string';

/**
 * Renders the home page in the system.
 *
 * @returns { Element } The layout of the home page
 */
export default function Home() {
	/**
	 * Sample collection of data for the accommodation cards
	 *
	 * @param {string} thumbnail The filename of the accommodation's thumbnail
	 * @param {string} name The name of the accommodation
	 * @param {string} location The location of the accommodation
	 * @param {string} type The type of accommodation offered
	 * @param {number} minPrice The minimum price to stay at the accommodation
	 */

	const [accomDetails, setAccomDetails] = useState(null);
	const [accomInfo, setAccomInfo] = useState([]);
	const [filterState, updateFilterState] = useState([]);
	const [sortState, updateSortState] = useState(1);
	const [page, setPage] = useState(1);
	const [pages, setPages] = useState(1);

	const searchValue = queryString.parse(location.search).accommodationName;

	const getDetails = async () => {
		const service = new AccommService();
		try {
			setAccomDetails(null);
			let res = await service.viewAllAccommodation(
				searchValue,
				filterState,
				sortState,
				page
			);
			if (res === undefined) throw new Error();
			setAccomDetails(res);
		} catch (err) {
			console.log(err);
		}
	};

	const handleChangeFilter = (newValue, action) => {
		action === 0
			? // append
			  updateFilterState([...filterState, newValue])
			: // delete
			  updateFilterState((prevState) =>
					prevState.filter((element) => element !== newValue)
			  );
		getDetails();
	};

	const handleChangeSort = (newValue) => {
		updateSortState(newValue);
		getDetails();
	};

	const handleDeleteFilter = (event, toDel) => {
		event.preventDefault();
		updateFilterState(filterState.filter((element) => element != toDel));
	};

	const handleChangePage = (event, value) => {
		setPage(value);
	};

	//source: https://stackoverflow.com/questions/27030/comparing-arrays-of-objects-in-javascript
	const objectsEqual = (o1, o2) =>
		Object.keys(o1).length === Object.keys(o2).length &&
		Object.keys(o1).every((p) => o1[p] === o2[p]);

	// retrieve data from the database (via backend) every render
	useEffect(() => {
		getDetails();
	}, [page]);

	useEffect(() => {
		if (accomDetails && !objectsEqual(accomDetails, accomInfo)) {
			setAccomInfo(accomDetails.sorted);
			setPages(accomDetails.pages);
		}
	}, [accomDetails]);

	return (
		<React.Fragment>
			{/* Used grids instead of flex boxes*/}
			{/* Can be responsive by adding sizes per each grid item*/}
			<Grid container justifyContent={'center'}>
				{/* Start of Accommodation Header */}
				<Grid item xs={10}>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<Box sx={{ marginBottom: 6 }}>
							<AccommodationHeader
								title={
									searchValue === undefined
										? 'Discover Accommodations'
										: 'Search results for: "' + searchValue + '"'
								}
								filterState={filterState}
								handleChangeFilter={handleChangeFilter}
								handleChangeSort={handleChangeSort}
								handleDeleteFilter={handleDeleteFilter}
							/>
						</Box>
					</Box>
				</Grid>
				{/* End of Accommodation Header */}

				{/* Start of Carousel */}
				<Grid item xs={10.25}>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<Box sx={{ marginBottom: 6 }}>
							<CarouselCards info={accomInfo} />
						</Box>
					</Box>
				</Grid>
				{/* End of Carousel */}

				{/* Space Allowance */}
				<Grid item xs={10}>
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						{/* Pagination */}
						<Pagination
							count={pages}
							variant="outlined"
							onChange={handleChangePage}
							sx={{ margin: 'auto' }}
						/>
						{/* End of Pagination */}
					</Box>
					<Box sx={{ marginBottom: 2 }}></Box>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

Home.propTypes = {
	userName: PropTypes.string,
	userPicture: PropTypes.string,
	userType: PropTypes.number,
};
