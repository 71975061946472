import { createTheme } from "@mui/material/styles";

/**
 * This is the primary theme of the `Favorite` button
 * in its default state.
 *
 * 1) primary.main - Refers to the ICON color
 * 2) secondary.main - Refers to the BACKGROUND color
 */
export const FavoriteButtonTheme = createTheme({
  palette: {
    primaryColor: {
      main: "#e23e57",
    },
    primaryBGColor: {
      main: "#f7cad1",
    },
    secondaryColor: {
      main: "#fefefe",
    },
    secondaryBGColor: {
      main: "#e23e57",
    },
  },
});

/**
 * This is the primary theme of the `Favorite` button
 * in its active state.
 *
 * 1) primary.main - Refers to the ICON color
 * 2) secondary.main - Refers to the BACKGROUND color
 */
export const FavoritedButtonTheme = createTheme({
  palette: {
    primary: {
      main: "#fefefe",
    },
    secondary: {
      main: "#e23e57",
    },
  },
});

/**
 * This is the primary theme of the `Add` button.
 *
 * 1) primary.main - Refers to the ICON color
 * 2) secondary.main - Refers to the BACKGROUND color
 */
export const AddButtonTheme = createTheme({
  palette: {
    primary: {
      main: "#009432",
    },
    secondary: {
      main: "#cde9d7",
    },
  },
});

/**
 * This is the primary theme of the `Remove` button.
 *
 * 1) primary.main - Refers to the ICON color
 * 2) secondary.main - Refers to the BACKGROUND color
 */
export const RemoveButtonTheme = createTheme({
  palette: {
    primary: {
      main: "#d32f2f",
    },
    secondary: {
      main: "#f3caca",
    },
  },
});

export const FilterButtonTheme = createTheme({
  palette: {
    primary: {
      main: "#FE7968",
    },
    secondary: {
      main: "#fefefe",
    },
  },
});

export const ReturnButtonTheme = createTheme({
  palette: {
    primary: {
      main: "#FE7968",
    },
  },
});
