import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea, Link } from '@mui/material';
import { PropTypes } from 'prop-types';
import FavoriteButton from '../components/button/FavoriteButton';
import Rating from '../components/Rating';
import useAuth from '../hooks/useAuth';
import { FILTER_CHOICES, USER } from '../utils/constants';

/**
 * Renders a `profile` card containing information
 * about the accommodation place.
 *
 * @param { String } thumbnail    A URL to the image preview of the place
 * @param { String } name         The full name of the accommodation place
 * @param { String } location     The location of the accommodation place
 * @param { String } type         The type of accommodation
 * @param { float }  minPrice     The minimum price of the place if rented
 * @param { Integer } rating      The rating of the accommodation
 * @param { String } id           ID of the accommodation
 * @param { String } ownerId      ID of the accommodation owner
 *
 * @returns { Card }  A profile card about the accommodation
 */
export default function AccommodationCard({
	thumbnail,
	name,
	location,
	type,
	minPrice,
	rating,
	id,
	ownerId,
}) {
	const { auth } = useAuth();
	const [faveClicked, setFaveClicked] = React.useState(
		auth.favorites.includes(id)
	);

	// Function for handling click
	const handleFaveClicked = () => {
		setFaveClicked(!faveClicked);
	};

	return (
		<Card sx={{ maxWidth: 250, boxShadow: 0, position: 'relative' }}>
			{(auth.role === USER.TYPE.OWNER || auth.role === USER.TYPE.REGISTERED) &&
				auth.id !== ownerId && (
					<Box
						sx={{
							position: 'absolute',
							top: 10,
							right: 10,
							zIndex: 99,
						}}
						onClick={handleFaveClicked}
					>
						<FavoriteButton
							isPressed={faveClicked}
							userID={auth.id}
							accomID={id.toString()} // passed parameters
						/>
					</Box>
				)}

			<Link href={'/accommodation/?id=' + id} underline={'none'}>
				<CardMedia
					component="img"
					height="250"
					image={thumbnail}
					alt="Accommodation Place"
				/>

				<CardActionArea sx={{ position: 'relative' }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							position: 'absolute',
							right: 10,
							bottom: 110,
						}}
					>
						<Rating rating={rating == -1 ? 'N/A' : rating} />
					</Box>

					<CardContent
						sx={{
							paddingLeft: 0,
							paddingRight: 0,
							paddingBottom: 0,
							paddingTop: 1.5,
						}}
					>
						<Typography gutterBottom variant="h5" component="div">
							{name}
						</Typography>

						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<Typography
									variant="body1"
									color="black"
									sx={{ fontWeight: 600 }}
								>
									{location}
								</Typography>

								<Typography
									variant="body1"
									sx={{ fontWeight: 'medium', color: 'gray', marginTop: -0.5 }}
								>
									{FILTER_CHOICES[0].choices[type]}
								</Typography>
							</Box>

							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<Typography
									gutterBottom
									variant="body2"
									component="div"
									sx={{
										marginLeft: 'auto',
										marginRight: 0,
										textAlign: 'right',
										color: 'gray',
									}}
								>
									{'Starts at'.toUpperCase()}
								</Typography>

								<Typography
									variant="body2"
									color="text.secondary"
									sx={{
										marginLeft: 'auto',
										marginRight: 0,
										textAlign: 'right',
										fontSize: 'h6.fontSize',
										color: 'black',
										position: 'relative',
										top: -10,
									}}
								>
									PHP {minPrice}
								</Typography>
							</Box>
						</Box>
					</CardContent>
				</CardActionArea>
			</Link>
		</Card>
	);
}

AccommodationCard.propTypes = {
	thumbnail: PropTypes.string,
	name: PropTypes.string,
	location: PropTypes.string,
	type: PropTypes.string,
	minPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	rating: PropTypes.number,
	id: PropTypes.string,
	ownerId: PropTypes.string,
};
