import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import PropTypes from 'prop-types';
import TemplateModal from './common/Template';
import { BACKEND_URI } from '../../utils/constants';

/**
 * This includes a form that allows a user to
 * log in through Google Authentication.
 *
 * @param { bool } openState    Indicates if the modal should open or not
 * @param { func } handleClose  Handles how the modal will be closed
 *
 * @returns { Modal } A ready-to-use modal including the button
 */
export default function LoginModal({ openState, handleClose }) {
	// Proceed to Google Authentication (separate page).
	const gotoGoogleAuth = () => {
		window.open(`${BACKEND_URI}/api/auth/login`, '_self');
	};

	return (
		<TemplateModal
			icon={<GoogleIcon />}
			title="Log In"
			openState={openState}
			handleClose={handleClose}
		>
			<Box
				sx={{
					p: '2rem',
					display: 'flex',
					flexDirection: 'column',
					gap: '2.25rem',
				}}
			>
				{/* Start of Description */}
				<Box>
					<Typography variant="h6" sx={{ mb: '0.4rem' }}>
						Join the Community
					</Typography>
					<Typography
						variant="body1"
						sx={{ fontSize: '1.15rem', color: 'grey.800' }}
					>
						Use your <b>Google Account</b> now to <b>start looking</b> for
						places <br />
						to stay for you and your friends!&nbsp;&nbsp;&nbsp;
						<i style={{ color: 'var(--gray)' }}>It&apos;s free</i>
					</Typography>
				</Box>
				{/* End of Description */}

				{/* Start of Button */}
				<Button
					onClick={gotoGoogleAuth}
					variant="outlined"
					fullWidth
					sx={{
						whiteSpace: 'nowrap',
						gap: '0.8rem',
					}}
				>
					<GoogleIcon sx={{ fontSize: '1rem' }} />
					LOG IN WITH GOOGLE
				</Button>
				{/* End of Button */}
			</Box>
		</TemplateModal>
	);
}

LoginModal.propTypes = {
	openState: PropTypes.bool,
	handleClose: PropTypes.func,
};
