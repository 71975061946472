import React from 'react';
import { theme } from './context/ThemeContext';
import { ThemeProvider, Grid } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import WizardProvider from './context/WizardContext';
import Header from './components/common/Header';
import Footer from './components/common/Footer';

import Home from './pages/Home';
import AccommodationProfile from './pages/Accommodation/Profile';
import Form from './pages/Accommodation/Form';
import NotFoundPage from './pages/Error/NotFoundPage';
import UnauthorizedPage from './pages/Error/UnauthorizedPage';
import { USER } from './utils/constants';
import RequireAuth from './pages/RoleOutlet';
import AdminProfile from './pages/Profile/AdminProfile';
import OwnerProfile from './pages/Profile/OwnerProfile';
import UserProfile from './pages/Profile/UserProfile';
import { Toaster } from 'react-hot-toast';

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Toaster position="bottom-right" />

			<Grid container>
				<BrowserRouter>
					{/* Start of Header */}
					<Grid container xs={12} sx={{ mt: 8, mb: 8 }}>
						<Grid item xs={1}></Grid>
						<Grid item xs={10}>
							<Header />
						</Grid>
						<Grid item xs={1}></Grid>
					</Grid>
					{/* End of Header */}

					<Routes>
						{/* Catalog & Error Pages */}
						<Route exact={true} path="/" element={<Home />} />
						<Route exact={true} path="/*" element={<NotFoundPage />} />
						<Route
							exact={true}
							path="/unauthorized"
							element={<UnauthorizedPage />}
						/>

						{/* Accommodation Owner */}
						<Route
							element={
								<RequireAuth
									allowedRoles={[USER.TYPE.ADMIN, USER.TYPE.OWNER]}
								/>
							}
						>
							{/* Profile */}
							<Route path="/profile/owner" element={<OwnerProfile />} />

							{/* Add Accommodation */}
							<Route
								exact={true}
								path="/add/accommodation"
								element={
									<WizardProvider>
										<Form />
									</WizardProvider>
								}
							/>

							{/* Edit Accommodation */}
							<Route
								exact={true}
								path="/edit/accommodation"
								element={
									<WizardProvider>
										<Form isEdit />
									</WizardProvider>
								}
							/>
						</Route>

						{/* Registered User */}
						<Route
							element={
								<RequireAuth
									allowedRoles={[USER.TYPE.ADMIN, USER.TYPE.REGISTERED]}
								/>
							}
						>
							{/* Profile */}
							<Route
								exact={true}
								path="/profile/user"
								element={<UserProfile />}
							/>
						</Route>

						{/* Admin */}
						<Route element={<RequireAuth allowedRoles={[USER.TYPE.ADMIN]} />}>
							{/* Profile */}
							<Route
								exact={true}
								path="/profile/admin"
								element={<AdminProfile />}
							/>
						</Route>

						{/* Accommodation Profile */}
						<Route
							exact={true}
							path="/accommodation/"
							element={<AccommodationProfile />}
						/>
					</Routes>

					{/* Start of Footer */}
					<Grid container xs={12} sx={{ mt: 2, mb: 6 }}>
						<Grid item xs={1}></Grid>
						<Grid item xs={10}>
							<Footer />
						</Grid>
						<Grid item xs={1}></Grid>
					</Grid>
					{/* End of Footer */}
				</BrowserRouter>
			</Grid>
		</ThemeProvider>
	);
}

export default App;
