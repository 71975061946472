import BaseService from './base.service';

class AccommOwnerService extends BaseService {
	/**
	 * Retrieve all accommodations of the current accommodation owner
	 *
	 *
	 * @param { String } ownerId  The ID of the desired accommodation
	 *
	 * @returns { Promise<{ reviewDetails: Array | undefined }> } An array containing all the owner's accommodations
	 */
	async allOwnerAccommodations(ownerId, pageNum) {
		try {
			const res = await this.axios.get(
				`/api/accom/owner/${ownerId}/?page=${pageNum}`
			);

			const { data } = res.data;
			return data;
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * Add an application to become an accommodation
	 * owner in the system.
	 *
	 * @param { Object } application  Contains the email & documents
	 */
	async applyOwner(application) {
		try {
			const res = await this.axios.post('/api/user/apply', application, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			const { data } = res;
			return data;
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * Accept the application of a registered user.
	 *
	 * @param { String } ownerId  The ID of the applicant
	 */
	async acceptApplication(ownerId) {
		try {
			const res = await this.axios.patch(`/api/admin/accept/${ownerId}`);
			const { data } = res;
			return data;
		} catch (err) {
			// console.log(err);
			return err;
		}
	}

	/**
	 * Reject the application of a registered user.
	 *
	 * @param { String } ownerId  The ID of the applicant
	 */
	async rejectApplication(ownerId) {
		try {
			const res = await this.axios.patch(`/api/admin/reject/${ownerId}`);
			const { data } = res;
			return data;
		} catch (err) {
			// console.log(err);
			return err;
		}
	}
}

export default AccommOwnerService;
