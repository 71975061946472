import * as yup from 'yup';
import { FormatError, RequiredError } from '../errors/schema.errors';
import { PhoneNumberFormat, PhoneNumberHelperText } from '../constants';

/**
 * Involves only the contact number for accommodation
 * owners. Disregard the `type` field.
 *
 * @returns { Object }  Schema of the edit form in the profile
 */
const profileSchema = yup.object().shape({
	type: yup.number().required(RequiredError('account type')),
	contactNumber: yup
		.string()
		.required(RequiredError('contact number'))
		.matches(PhoneNumberFormat, FormatError(PhoneNumberHelperText)),
});

/**
 * Contains the initial values of the form.
 *
 * @returns { Object }  Contains the initial values
 */
const initialValues = {
	type: 0,
	contactNumber: '',
};

export { profileSchema, initialValues };
