import React from 'react';
import ErrorPage from './ErrorPage';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

/**
 * Renders the error page for routes that
 * don't exist in the system.
 *
 * @returns { Element } The layout for the Error 404 page
 */
export default function NotFoundPage() {
	return (
		<ErrorPage
			icon={<HelpOutlineOutlinedIcon sx={{ fontSize: '9rem' }} />}
			code="404"
			name="Page Not Found"
			message={
				<span>
					The page you are looking for might have been removed, had its <br />
					name changed, or is temporarily unavailable.
				</span>
			}
		/>
	);
}
