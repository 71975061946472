import React, { useState } from "react";
import { PropTypes } from "prop-types";

import {
  Collapse,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  ThemeProvider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
} from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { FilterButtonTheme } from "../context/ButtonContext";

/**
 * Creates a collapsible card per category. Options per category will be displayed
 * once expanded.
 *
 * @param { string } title  The title of the category
 * @param { Array } choices The available filtering choices for the category
 * @param { Array } value
 * @param { func } onChange Executes a function once an input is changed
 *
 * @returns { Card }  A collapsible card with the filtering options for the category
 */
export default function FilterCard({ title, choices, value, onChange }) {
  const [open, setOpen] = useState(false);

  function handleChange(event, i) {
    const {
      target: { checked },
    } = event;
    checked === true ? onChange(i, 0) : onChange(i, 1);
  }
  return (
    <ThemeProvider theme={FilterButtonTheme}>
      <Card
        sx={{
          boxShadow: 0,
          width: 270,
        }}
      >
        <CardHeader
          title={title}
          disableTypography={true}
          sx={{
            fontWeight: 600,
            fontSize: 14,
          }}
          action={
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          }
          onClick={() => setOpen(!open)}
        />
        <div
          style={{
            backgroundColor: "rgba(211,211,211,0.4)",
          }}
        >
          <Collapse
            sx={{
              backgroundColor: "#fefefe",
            }}
            in={open}
            timeout="auto"
          >
            <CardContent>
              <FormControl>
                <FormGroup sx={{ display: "relative" }}>
                  {choices.map((i) => (
                    <FormControlLabel
                      key={i}
                      value={i}
                      control={
                        <Checkbox checked={value.includes(i) ? true : false} />
                      }
                      label={i}
                      onChange={(event) => handleChange(event, i)}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </CardContent>
          </Collapse>
        </div>
      </Card>
    </ThemeProvider>
  );
}

FilterCard.propTypes = {
  title: PropTypes.string,
  choices: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
};
