import BaseService from "./base.service";

class NotificationService extends BaseService {
  /**
   * Retrieve notifications for user
   * from the server.
   *
   * @param { String } id  The ID of the user/owner/admin
   *
   * @returns { Promise<{ notifDetails: Array | undefined }> } An array of reviews
   */
  async getAllNotifications(id) {
    try {
      const res = await this.axios.get(`/api/user/notif/${id}`);
      const { data } = res.data;
      return data;
    } catch (err) {
      console.log(err);
    }
  }
}

export default NotificationService;
