import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'better-react-carousel';
import AccommodationCard from '../AccommodationCard';
import accomm_place from '../../assets/images/accomm_place.jpg';

/**
 * Creates a carousel of accommodations that fills up 2 rows divided by 5 columns
 *
 * @param { array } info  An array of objects containing details of accommodations
 *
 * @returns { Carousel }  A carousel containing cards detailing each accommodation
 */
export default function CarouselCards({ info }) {
	// Get the average ratingg.
	const average = (arr) => {
		if (arr.length === 0) return -1;
		else return arr.reduce((p, c) => p + c, 0) / arr.length;
	};

	return (
		<Carousel cols={5} rows={2} gap={80}>
			{info.map((detail, i) => (
				<Carousel.Item key={i}>
					<AccommodationCard
						thumbnail={(detail.pictures && detail.pictures[0]) || accomm_place}
						name={detail.accommodationName}
						location={detail.location}
						type={detail.type}
						minPrice={detail.price[0]}
						rating={average(detail.overallRating)}
						id={detail._id}
						ownerId={detail.ownerId}
					/>
				</Carousel.Item>
			))}
		</Carousel>
	);
}

CarouselCards.propTypes = {
	info: PropTypes.arrayOf(
		PropTypes.shape({
			thumbnail: PropTypes.string,
			name: PropTypes.string,
			location: PropTypes.string,
			type: PropTypes.string,
			minPrice: PropTypes.number,
		})
	),
};
