import * as yup from 'yup';
import { RequiredError } from '../errors/schema.errors';

/**
 * Includes the e-mail of the account as well as
 * some file uploads.
 *
 * TODO: Add more files to the form.
 *
 * @returns { Object }  Schema of the edit form in the profile
 */
const ownerSchema = yup.object().shape({
	files: yup.mixed().required(RequiredError('files')),
});

/**
 * Contains the initial values of the form.
 *
 * @returns { Object }  Contains the initial values
 */
const initialValues = {
	files: [],
};

export { ownerSchema, initialValues };
