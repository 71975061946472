/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import NoReview from '../review/NoReview';
import ReviewAccommProfile from '../review/ReviewProfile';
import TemplateModal from './common/Template';
import StarIcon from '@mui/icons-material/Star';
import { Box } from '@mui/material';

/**
 * Renders a modal "Show All Reviews" modal containing all of the reviews
 *
 * @param { array } review  An array of objects containing details of accommodations
 *
 * @returns { modal }  A carousel containing cards detailing each accommodation
 */
export default function ShowReviewModal({ review, openState, handleClose }) {
	return (
		<TemplateModal
			icon={<StarIcon />}
			title={
				review && review.length > 1
					? 'All Reviews'
					: review && review.length > 0
					? 'User Review'
					: 'No Review'
			}
			openState={openState}
			handleClose={handleClose}
		>
			<Box
				sx={{
					p: '2rem',
					display: 'flex',
					flexDirection: 'column',
					gap: '1rem',
				}}
			>
				{review && review.length > 0 ? (
					review.map((r) => {
						return <ReviewAccommProfile review={r} isProfile={true} />;
					})
				) : (
					<NoReview />
				)}
			</Box>
		</TemplateModal>
	);
}

ShowReviewModal.propTypes = {
	review: PropTypes.arrayOf(
		PropTypes.shape({
			username: PropTypes.string,
			rating: PropTypes.number,
			date: PropTypes.string,
			description: PropTypes.string,
		})
	),

	openState: PropTypes.bool,
	handleClose: PropTypes.func,
};
