import React from "react";
import StarIcon from "@mui/icons-material/Star";
import { yellow } from "@mui/material/colors";
import { PropTypes } from "prop-types";
import { Typography, Box } from "@mui/material";

/**
 * Renders a small label for rating an
 * accommodation place.
 *
 * @param { float } rating  The star rating of a user for a place
 *
 * @returns { div }  A label of the rating and a star
 */
export default function Rating({ rating }) {
  return (
    <Box
      id="rating"
      sx={{
        backgroundColor: "var(--white)",
        paddingLeft: 0.9,
        borderRadius: 1,
      }}
    >
      <Typography variant="body1" id="rating-rate">
        {rating}&nbsp;&nbsp;&nbsp;
      </Typography>
      <StarIcon id="rating-star" sx={{ color: yellow[800] }} />
      &nbsp;&nbsp;&nbsp;
    </Box>
  );
}

Rating.propTypes = {
  rating: PropTypes.number,
};
