// Format (Regular Expressions)
export const PhoneNumberFormat = '^09\\d{9}$';

// Format (Label)
export const PhoneNumberHelperText = '09XXYYYZZZZ';

// User Types
export const USER = {
	TYPE: {
		ADMIN: 1,
		OWNER: 2,
		REGISTERED: 3,
	},
};

// File Constants
export const MAX_FILE_SIZE = 2000000; // 2 MB
export const SUPPORTED_IMAGE_TYPES = ['image/jpeg', 'image/png'];
export const SUPPORTED_FILE_TYPES = ['application/pdf'];

export const FILE_SIZE_FUNCNAME = 'validateFileSize';
export const FILE_TYPE_FUNCNAME = 'validateFileType';

// Report Status
export const REPORT_STATUS = {
	RESOLVED: 'resolved',
	REJECTED: 'rejected',
};

// Filter Choices
export const FILTER_CHOICES = [
	{
		title: 'Accommodation Type',
		choices: ['Hotel', 'Apartment', 'Bed Space', 'Dormitory', 'Transient'],
	},
	{
		title: 'Price Range',
		choices: [
			'Under Php 2,000',
			'Php 2,000 - Php 4,000',
			'Php 4,000 - Php 6,000',
			'Above Php 6,000',
		],
	},
	{
		title: 'Baranggay',
		choices: ['Batong Malake', 'San Antonio', 'Mayondon', 'Anos', 'Others'],
	},
	{
		title: 'Accepted Tenants',
		choices: ['Females Only', 'Males Only', 'Coed'],
	},
];

// BACKEND URI
export const BACKEND_URI = 'https://one28-back.onrender.com';
