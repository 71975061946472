import React from "react";
import PropTypes from "prop-types";
import { Divider, Box, Button } from "@mui/material";

/**
 * Renders the footer of the modal that contains
 * the buttons (usually Submit & Cancel).
 *
 * @param { String } label        The label of the success button
 * @param { bool } disabled       Indicates if a button is disabled
 * @param { func } handleSuccess  Executes if the user confirms the action
 * @param { func } handleClose    Handles the closing of the modal
 * @param { String } palette      The palette of the success buton (Default: RED)
 *
 * @returns { Element } The footer of the modal
 */
export default function ModalFooter({
  label,
  disabled,
  handleSuccess,
  handleClose,
  palette = "primary",
}) {
  return (
    <React.Fragment>
      {/* Start of Buttons */}
      <Divider light />
      <Box
        sx={{
          p: "2rem",
          gap: "1rem",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {/* Success Button */}
        <Button
          variant="contained"
          color={palette}
          type="submit"
          disabled={disabled || false}
          onClick={handleSuccess}
          fullWidth
        >
          {label}
        </Button>

        {/* Cancel Button */}
        <Button
          variant="outlined"
          color="neutral"
          onClick={handleClose}
          fullWidth
        >
          Cancel
        </Button>
      </Box>
      {/* End of Buttons */}
    </React.Fragment>
  );
}

ModalFooter.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  handleSuccess: PropTypes.func,
  handleClose: PropTypes.func,
  palette: PropTypes.string,
};
