import React from 'react';
import { Box, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

/**
 *  This component renders a label and text input
 * field for a form.
 *
 * @param { String } label      The label for the text input field.
 * @param { Element } component The field to be displayed
 *
 * @returns { Element } A JSX element that displays the text input field for the form.
 */
export default function FieldContainer({ label, component }) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '0.5rem',
			}}
		>
			{/* Label */}
			<Typography variant="h6" sx={{ minWidth: 160, fontSize: '1rem' }}>
				{label}
			</Typography>

			{/* Text Field */}
			{component}
		</Box>
	);
}

FieldContainer.propTypes = {
	label: PropTypes.string,
	component: PropTypes.element,
};
