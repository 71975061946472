import axios from "axios";
import { BACKEND_URI } from "../utils/constants";

class BaseService {
  constructor() {
    this.axios = axios.create({
      baseURL: BACKEND_URI,
      withCredentials: false,
    });
  }
}

export default BaseService;
