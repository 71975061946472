import React from 'react';
import PropTypes from 'prop-types';
import { USER } from '../utils/constants';

const AuthContext = React.createContext({});

/**
 * Serves as the 'global' state of user data after
 * authentication.
 *
 * To change the role, change the initial state in this file.
 *
 * To use, import and invoke `useAuth`. Then, deconstruct the
 * object. For example:
 *
 * `const { auth, setAuth } = useAuth()`
 *
 * @param { Element } children  App.js
 *
 * @returns { AuthContext.Provider }  A ready-to-use provider of user data
 */
export const AuthProvider = ({ children }) => {
	/**
	 *
	 * @param { String } id             Identifier of user's Google account
	 * @param { String } firstName      The user's first name
	 * @param { String } lastName       The user's family/last name
	 * @param { Integer } role          The account type of the user
	 * @param { String } contactNumber  Contact information of the user
	 * @param { String } email          Email address of the user
	 */
	const [auth, setAuth] = React.useState({
		id: '',
		firstName: '',
		lastName: '',
		role: USER.TYPE.UNREGISTERED, // Increment by 1.
		contactNumber: '',
		email: '',
		picture: null,
		favorites: [],
	});

	return (
		<AuthContext.Provider
			value={{
				auth,
				setAuth,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;

AuthProvider.propTypes = {
	children: PropTypes.any,
};
