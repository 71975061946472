import React from "react";
import { PropTypes } from "prop-types";
import { Button, Box } from "@mui/material";

/**
 * Renders two (2) buttons: one for going back to
 * a previous step and another for proceeding or
 * submitting the form.
 *
 * @param { int } activeStep  The index of the section being viewed currently
 * @param { int } stepCount   The total number of steps in the form
 * @param { bool } isProceed  Indicates if the form can proceed to the next section
 * @param { func } handleBack A function that goes back to the prior section
 *
 * @returns { React.Fragment } A container holding the back and proceed (or submit) buttons
 */
export default function StepButton({
  activeStep,
  stepCount,
  isProceed,
  handleBack,
}) {
  // TODO: Make the container full width.
  const isFinalStep = activeStep === stepCount - 1;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      {/* Back Button */}
      {activeStep !== 0 ? (
        <Button variant="text" onClick={handleBack}>
          Back
        </Button>
      ) : (
        <Box></Box>
      )}

      {/* Proceed/Submit Button */}
      <Button variant="outlined" disabled={isProceed} type="submit">
        {isFinalStep ? "Submit" : "Proceed"}
      </Button>
    </Box>
  );
}

StepButton.propTypes = {
  activeStep: PropTypes.number,
  stepCount: PropTypes.number,
  isProceed: PropTypes.bool,
  handleBack: PropTypes.func,
};
