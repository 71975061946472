import BaseService from './base.service';

class ReviewService extends BaseService {
	/**
	 * Retrieve previews of data of all reviews
	 * from the server.
	 *
	 * @param { String } accomId  The ID of the desired accommodation
	 *
	 * @returns { Promise<{ reviewDetails: Array | undefined }> } An array of reviews
	 */
	async viewAllReviews(accomId) {
		try {
			const res = await this.axios.get(`/api/user/review/${accomId}/?page=1`);
			const { data } = res.data;
			return data;
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * Adds the review of a user to the server.
	 *
	 * @param { Object } review   The review of the user to be added
	 *
	 * @returns { AxiosResponse } Indicates if the request is successful or not
	 */
	async addReview(review) {
		try {
			const res = await this.axios.post(`/api/user/review`, review);
			const { data } = res;
			return data;
		} catch (err) {
			console.log(err);
		}
	}
}

export default ReviewService;
