import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Box, IconButton } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import toast from 'react-hot-toast';
import AccommOwnerService from '../services/owner.service';

/**
 * Displays information about the application
 * of a certain registered user.
 *
 * @returns { Element } A ready-to-use component containing the applicatin
 */
export default function ApplyCard({ application }) {
	/**
	 * Handle the acceptance of an application.
	 *
	 * @param { String } ownerId  The ID of the applicant
	 */
	const handleAccept = async (ownerId) => {
		const service = new AccommOwnerService();
		try {
			const res = await service.acceptApplication(ownerId);
			if (res.success) {
				toast.success(res.message, { duration: 3000 });
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			} else {
				toast.error(res.message, { duration: 5000 });
			}
			return res;
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * Handle the rejection of an application.
	 *
	 * @param { String } ownerId  The ID of the applicant
	 */
	const handleReject = async (ownerId) => {
		const service = new AccommOwnerService();
		try {
			const res = await service.rejectApplication(ownerId);
			if (res.success) {
				toast.success(res.message, { duration: 3000 });
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			} else {
				toast.error(res.message, { duration: 5000 });
			}
			return res;
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Paper elevation={3} sx={{ p: '2rem', width: '60%' }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				{/* Start of Information */}
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '0.25rem',
					}}
				>
					{/* Name */}
					<Typography variant="h5" color="primary">
						{application.ownerName}
					</Typography>

					{/* Status */}
					<Typography variant="body1" sx={{ fontWeight: 600 }}>
						Status:{' '}
						<span style={{ color: 'var(--red)' }}>
							{application.status.toUpperCase()}
						</span>
					</Typography>

					{/* Link */}
					<Box sx={{ mt: '1rem' }}>
						<a href={application.files[0]} target="_blank" rel="noreferrer">
							View Document
						</a>
					</Box>
				</Box>
				{/* End of Information */}

				{/* Start of Check/Cross */}
				{application.status !== 'pending' || (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							gap: '0rem',
						}}
					>
						<IconButton
							sx={{ height: '2rem' }}
							onClick={() => handleAccept(application.ownerId)}
						>
							<CheckCircleOutlineOutlinedIcon
								sx={{ fontSize: '2rem', color: '#4d7c0f' }}
							/>
						</IconButton>

						<IconButton
							sx={{ height: '2rem' }}
							onClick={() => handleReject(application.ownerId)}
						>
							<CancelOutlinedIcon sx={{ fontSize: '2rem', color: '#b91c1c' }} />
						</IconButton>
					</Box>
				)}
				{/* End of Check/Cross */}
			</Box>
		</Paper>
	);
}

ApplyCard.propTypes = {
	application: PropTypes.object,
};
