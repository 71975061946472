import React from "react";
import stals_logo from "../../assets/images/stals_logo.png";
import { Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import { getProfileLink } from "../../utils/helpers";
import useAuth from "../../hooks/useAuth";

/**
 * Renders a description about the system, links,
 * and other related information
 *
 * @returns { Element }   A ready-to-use Footer component
 */
export default function Footer() {
  const { auth } = useAuth();
  return (
    <Box className="footer">
      <Box className="footer_contents">
        {/* Start of STALS Section */}
        <Box className="footer_contents_stals">
          <img className="logo_place" src={stals_logo} alt="STALS logo" />
          <br />
          <Typography
            variant="body1"
            sx={{ textAlign: "justify", mt: "1.5rem" }}
          >
            STALS is an information system that will allow users to search, get
            information, review, and report various accommodations available in
            Los Baños, Laguna. It aims to provide a user-friendly platform that
            streamlines the accommodation search process of the users.
          </Typography>
        </Box>
        {/* End of STALS Section */}

        {/* Start of Links Section */}
        <Box className="footer_contents_explore">
          <Typography variant="h5" sx={{ color: "var(--dark-gray)" }}>
            Explore
          </Typography>

          <Typography
            variant="body1"
            sx={{ mt: "1.5rem", color: "var(--gray)" }}
          >
            {/* Home */}
            <Link to="/" className="home_button">
              Home
            </Link>{" "}
            <br />
            {/* Profile */}
            <Link
              to={`/profile/${getProfileLink(auth.role)}`}
              className="profile_button"
            >
              Profile
            </Link>
          </Typography>
        </Box>
        {/* End of Links Section */}

        {/* Start of Contact Section */}
        <Box className="footer_contents_contact">
          <Typography variant="h5" sx={{ color: "var(--dark-gray)" }}>
            Contact
          </Typography>

          <Typography variant="body1" sx={{ mt: "1.5rem" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.6rem",
              }}
            >
              <LocalPhoneIcon sx={{ color: "var(--red)", fontSize: "1rem" }} />
              +63912 345 6789
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.6rem",
              }}
            >
              <MailIcon sx={{ color: "var(--red)", fontSize: "1rem" }} />
              stals@gmail.com
            </Box>
          </Typography>
        </Box>
        {/* End of Contact Section */}

        {/* Start of About Section */}
        <Box className="footer_contents_about">
          <Typography variant="h5" sx={{ color: "var(--dark-gray)" }}>
            About Us
          </Typography>

          <Typography
            variant="body1"
            sx={{ textAlign: "justify", mt: "1.5rem" }}
          >
            We comprise of students from the University of the Philippines Los
            Baños that are currently taking CMSC 128 as part of our curriculum
            in BS Computer Science.
          </Typography>
        </Box>
        {/* End of About Section */}
      </Box>

      {/* Start of Copyright */}
      <Box className="footer_copyright">
        <Typography variant="">
          <br />
          <p>Copyright © 2023 CMSC 128 E-5L. All rights reserved.</p>
        </Typography>
      </Box>
      {/* End of Copyright */}
    </Box>
  );
}
