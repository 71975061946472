import React from "react";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useField } from "formik";
import PropTypes from "prop-types";

/**
 * Renders a modified select field.
 *
 * @param { String } name         Name of the involved field
 * @param { String } label        A display name (instead of the name)
 * @param { func } handleChange   Triggered if an option is selected
 * @param { Object } data         Set of name:value pairs
 * @param { bool } disabled       Indicates if the field is disabled or not
 *
 * @returns { Select } The modified select field with the properties
 */
export default function SelectField({
  name,
  label,
  handleChange,
  data,
  disabled,
  overrides,
}) {
  const [field, meta] = useField(name);
  const { value: selectedValue } = field;

  const { touched, error } = meta;
  const isError = touched && error;

  return (
    <FormControl error={isError}>
      <InputLabel id={name + "-label"}>Select {label}</InputLabel>
      <Select
        id={name}
        labelId={name + "-label"}
        label={`Select ${label}`}
        sx={{ width: "32rem" }}
        value={selectedValue ? selectedValue : ""}
        disabled={disabled}
        onChange={(e) => {
          field.onChange(e);
          if (handleChange) handleChange(e);
        }}
        {...overrides}
        {...field}>
        {data.map((option, i) => {
          return (
            <MenuItem
              key={i}
              value={option.value ? option.value : option.toLowerCase()}>
              {option.name ? option.name : option}
            </MenuItem>
          );
        })}
      </Select>

      {/* Error Message */}
      <FormHelperText>{isError ? error : undefined}</FormHelperText>
    </FormControl>
  );
}

SelectField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  overrides: PropTypes.object,
};
