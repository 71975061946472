import BaseService from './base.service';

class UserService extends BaseService {
	/**
	 * Retrieve the details of the current user
	 *
	 * @param { String } userId  The ID of the desired accommodation
	 *
	 * @returns { Promise<{ reviewDetails: Object | undefined }> } An object containing the user's details
	 */
	async userDetails(userId) {
		try {
			const res = await this.axios.get(`/api/user/${userId}`);
			const { data } = res.data;
			return data;
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * Edit details of the current user in
	 * their respective profile.
	 *
	 * @param { String } userId       The ID of the desired accommodation
	 * @param { Object } newDetails   Object containing the newly edited user details
	 *
	 */
	async editUserDetails(userId, newDetails) {
		try {
			const res = await this.axios.patch(`/api/user/${userId}`, newDetails);
			const { data } = res;
			return data.user;
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * Retrieves all the reviews of the current user
	 *
	 *
	 * @param { String } userId  The ID of the desired accommodation
	 *
	 * @returns { Promise<{ reviewDetails: array | undefined }> } An object containing the user's details
	 */
	async userReviews(userId) {
		try {
			const res = await this.axios.get(`/api/user/user-review/${userId}`);
			const { data } = res.data;
			return data;
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * Retrieves and returns the current user's
	 * informatin after logging in.
	 *
	 * @returns { Promise<{ user: Object | undefined }> } Contains the user's data
	 */
	async getLoggedUser() {
		try {
			const res = await this.axios.get('/api/auth/login/success', {
				withCredentials: true,
			});

			console.log("in frontend")
			console.log(res)
			return res.data.user;
		} catch (err) {
			return err;
		}

		// try {
		// 	const res = await this.axios.get('/api/auth/getUser', {
		// 		withCredentials: true,
		// 	});

		// 	console.log("in frontend")
		// 	console.log(res)
		// 	return res.data.user;
		// } catch (err) {
		// 	return err;
		// }
	}

	/**
	 * Log out of the system, and destroy
	 * the current session.
	 */
	async logout() {
		try {
			const res = await this.axios.post('/api/auth/logout', { withCredentials: true });
			console.log("logout")
			console.log(res)

			// if (res.data === 'out'){
			// 	window.location.href = "/"
			// }
			return res
		} catch (err) {
			return err;
		}
	}

	/**
	 * View all applications to become an
	 * accommodation owner.
	 *
	 * @returns { Object }  Details about the application
	 */
	async viewAllApplications() {
		try {
			const res = await this.axios.get('/api/admin');
			const { data } = res.data;
			return data;
		} catch (err) {
			console.log(err);
		}
	}
}

export default UserService;
