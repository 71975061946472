/* eslint-disable react/prop-types */
import React from 'react';
import { Avatar, Typography, Box } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import PropTypes from 'prop-types';
import profileIcon from '../../assets/images/no_picture.png';
import ShowReview from '../modals/ShowReview';
import UserService from '../../services/user.service';

/**
 * Renders a section for user review with the
 * inclusion of an avatar (or picture).
 *
 * @param { Array } info      An array of objects containing reviews of accommodations
 * @param { bool } isProfile  True if the user is viewing 1 review, false if user is viewing all reviews
 *
 * @returns { Element } A section containing a user review with an avatar/picture
 */
export default function ReviewProfile({ review, isProfile }) {
	const [user, setUser] = React.useState();

	// Get the user's name.
	const getUser = async () => {
		const service = new UserService();
		try {
			const res = await service.userDetails(review.userId);
			setUser(res);
		} catch (err) {
			console.log(err);
		}
	};

	React.useEffect(() => {
		getUser();
	}, []);

	// Populate the stars for the rating.
	let stars = [];
	for (let i = 0; i < 5; i++)
		stars.push(
			<StarIcon
				sx={{
					color: i > parseInt(review.rating) - 1 ? '#e0e0e0' : '#ffb300',
				}}
			/>
		);

	return (
		<Box sx={{ py: '1rem', width: '32rem' }}>
			{/* Start of Rating Box */}
			<Box
				sx={{
					display: 'block',
					justifyContent: 'space-between',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Avatar
							src={profileIcon} // NOTE: hardcoded
							sx={{
								width: 56,
								height: 56,
							}}
						/>

						{/* Start of Name & Date */}
						<Box
							sx={{
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<Typography variant="h6" sx={{ justifyContent: 'left' }}>
								{user?.fullName?.firstName} {user?.fullName?.lastName}
							</Typography>

							<Typography
								variant="body1"
								color="var(--gray)"
								sx={{
									fontWeight: 'medium',
									marginBottom: 1,
									justifyContent: 'left',
								}}
							>
								{review.createdAt.substring(0, 10)}
							</Typography>
						</Box>
						{/* End of Name & Date */}
					</Box>

					{/* Start of Rating */}
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'left',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							{stars.map((s) => {
								return s;
							})}
						</Box>
					</Box>
					{/* End of Rating */}
				</Box>

				<Typography
					variant="body1"
					sx={{
						mt: '1.2rem',
						justifyContent: 'left',
						textAlign: 'justify',
					}}
				>
					{review.desc.length > 198 && isProfile == false
						? review.desc.slice(0, 147) + '...'
						: review.desc}
				</Typography>

				<Typography
					variant="body1"
					color="var(--gray)"
					sx={{
						justifyContent: 'left',
						textDecoration: 'underline',
					}}
				>
					{review.desc.length > 198 && isProfile == false ? (
						<ShowReview review={review} />
					) : (
						''
					)}
				</Typography>
				{/* End of Rating Box */}
			</Box>
		</Box>
	);
}

ReviewProfile.propTypes = {
	review: PropTypes.arrayOf(
		PropTypes.shape({
			username: PropTypes.string,
			rating: PropTypes.number,
			date: PropTypes.string,
			description: PropTypes.string,
		})
	),

	isProfile: PropTypes.bool,
};
