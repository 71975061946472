import React, { useEffect, useState } from 'react';
import {
	Grid,
	Box,
	Typography,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import SelectField from '../../../components/form/fields/SelectField';
import { Field } from 'formik';
import AccommService from '../../../services/accommodation.service';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useFormikContext } from 'formik';

/**
 * Renders the additional fields of the accommodation
 * places, such as amenities and the like.
 *
 * @returns { Element } Layout containing fields of the section
 */
export default function AddInformation() {
	// Get the ID from the profile.
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const accomId = urlParams.get('id');

	const [accomDetails, setAccomDetails] = useState(null); // to store accommodation details

	const visitorTypes = [
		{ name: 'Not Allowed', value: 'not allowed' },
		{ name: 'Allowed, No Overnight', value: 'allowed, no overnight' },
		{ name: 'Overnight Allowed', value: 'overnight allowed' },
	];

	const billInclusions = [
		{ name: 'Wifi', value: 'wifi' },
		{ name: 'Water', value: 'water' },
		{ name: 'Electricity', value: 'electricity' },
	];

	const amenities = [
		{ name: 'Wifi', value: 'wifi' },
		{ name: 'Water', value: 'water' },
		{ name: 'Pets', value: 'pets' },
		{ name: 'Parking', value: 'parking' },
		{ name: 'CCTV', value: 'cctv' },
		{ name: 'Cooking', value: 'cooking' },
	];

	// fetch accommodation details and assign as initial values of the form
	const getAccomDetails = async (accomId) => {
		const service = new AccommService();
		try {
			const accomRes = await service.viewAccommodation(accomId);
			if (accomRes === undefined) throw new Error();
			setAccomDetails(accomRes);
		} catch (err) {
			console.log(err);
		}
	};

	const formik = useFormikContext();

	useEffect(() => {
		// Fetch acciommodation details
		getAccomDetails(accomId);
		// console.log("accomDetails: ", accomDetails);
	}, [accomDetails]);

	return (
		<React.Fragment>
			{/* Start of Space */}
			<Box sx={{ mt: 8 }}></Box>
			{/* End of Space */}

			{/* Start of Section */}
			<Grid container spacing={4}>
				{/* Bill Inclusions and Amenities */}
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '32px',
					}}
				>
					{/* Bill Inclusions */}
					<Box
						sx={{
							display: 'flex',
							alignItems: 'baseline',
							justifyContent: 'space-between',
						}}
					>
						<Typography variant="h6" sx={{ minWidth: 180 }}>
							Bill Inclusions
						</Typography>
						<Box sx={{ flexGrow: 1 }}>
							<Grid container spacing={4}>
								{/* Map through the bill inclusions and renders a set of checkboxes. Reference: https://stackoverflow.com/a/71664455 */}
								{billInclusions.map((inclusion) => (
									<Grid item xs={4} key={inclusion.value}>
										<Field
											type="checkbox"
											name="billInclusions"
											value={inclusion.value}
											as={FormControlLabel}
											control={
												accomDetails &&
												accomDetails.billInclusions.some(
													(e) => e === inclusion.name
												) ? (
													<Checkbox checked={true} />
												) : (
													<Checkbox />
												)
											}
											label={inclusion.name}
										/>
									</Grid>
								))}
							</Grid>
						</Box>
					</Box>

					{/* Amenities */}
					<Box
						sx={{
							display: 'flex',
							alignItems: 'first-baseline',
							justifyContent: 'space-between',
						}}
					>
						<Typography variant="h6" sx={{ minWidth: 180 }}>
							Amenities
						</Typography>
						<Box sx={{ flexGrow: 1 }}>
							<Grid container spacing={4}>
								{amenities.map((amenity) => (
									<Grid item xs={4} key={amenity.value}>
										<Field
											type="checkbox"
											name="amenities"
											value={amenity.value}
											as={FormControlLabel}
											control={
												accomDetails &&
												accomDetails.amenities.some(
													(e) => e === amenity.name
												) ? (
													<Checkbox checked={true} />
												) : (
													<Checkbox />
												)
											}
											label={amenity.name}
										/>
									</Grid>
								))}
							</Grid>
						</Box>
					</Box>
				</Box>
				<Grid item xs={2}></Grid>

				{/* Curfew and Visitors */}
				<Grid item xs={6}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '32px',
						}}
					>
						{/* Curfew */}
						<Box
							sx={{
								display: 'flex',
								alignItems: 'baseline',
								justifyContent: 'space-between',
							}}
						>
							<Typography variant="h6" sx={{ minWidth: 180 }}>
								Curfew
							</Typography>

							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<TimePicker
									label="Curfew"
									value={formik.values.curfew}
									onChange={(value) => {
										formik.setFieldValue('curfew', value);
									}}
									slotProps={{
										textField: {
											helperText: formik.errors.curfew,
											error: formik.touched.curfew && formik.errors.curfew,
										},
									}}
								/>
							</LocalizationProvider>
						</Box>

						{/* Visitors */}
						<Box
							sx={{
								display: 'flex',
								alignItems: 'baseline',
								justifyContent: 'space-between',
							}}
						>
							<Typography variant="h6" sx={{ minWidth: 180 }}>
								Visitors
							</Typography>

							<SelectField
								name="visitorsAllowed"
								label="Visitors"
								data={visitorTypes}
							/>
						</Box>
					</Box>
				</Grid>
			</Grid>
			{/* End of Section */}

			{/* Start of Space */}
			<Box sx={{ mt: 8 }}></Box>
			{/* End of Space */}
		</React.Fragment>
	);
}
