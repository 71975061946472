import React from 'react';
import { Box } from '@mui/material';
import InputField from '../form/fields/InputField';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { PhoneNumberHelperText } from '../../utils/constants';
import FieldContainer from '../form/FieldContainer';
import ModalFooter from './common/ModalFooter';
import TemplateModal from './common/Template';
import PhoneIcon from '@mui/icons-material/Phone';
import UserService from '../../services/user.service';
import toast from 'react-hot-toast';
import useAuth from '../../hooks/useAuth';
import {
	initialValues,
	profileSchema,
} from '../../utils/schema/profile.schema';

/**
 * This includes a form that allows a user to
 * log in through Google Authentication.
 *
 * @param { bool } openState    Indicates if the modal should open or not
 * @param { func } handleClose  Handles how the modal will be closed
 * @param { Object } payload    Contains the user's ID, role, and number.
 *
 * @returns { Modal } A ready-to-use modal including the button
 */
export default function EditModal({ openState, handleClose, payload }) {
	const { auth, setAuth } = useAuth();

	/**
	 * Edit the contact number of the
	 * accommodation owner.
	 *
	 * @param { String } newPhone  Phone number of the user
	 */
	const handleEdit = async (newPhone) => {
		const service = new UserService();
		try {
			const res = await service.editUserDetails(payload.id, {
				type: payload.role - 1,
				contactNumber: newPhone,
			});

			// Update the contact number.
			setAuth({
				...auth,
				contactNumber: newPhone,
			});

			// Close the modal.
			handleClose();

			// Display an alert/toast.
			if (res.success) toast.success(res.message, { duration: 3000 });
			else toast.error(res.message, { duration: 5000 });
		} catch (err) {
			console.log(err);
		}
	};

	// TODO: Place initial value after getting user profile.
	return (
		<TemplateModal
			icon={<PhoneIcon />}
			title="Edit Contact Number"
			openState={openState}
			handleClose={handleClose}
		>
			<Formik
				initialValues={initialValues}
				onSubmit={async (values) => {
					handleEdit(values.contactNumber);
				}}
				validationSchema={profileSchema}
			>
				{(formik) => {
					return (
						<Form>
							<Box>
								<Box sx={{ p: '2rem' }}>
									{/* Start of Field */}
									<FieldContainer
										label="Contact Number"
										component={
											<InputField
												name="contactNumber"
												label="Contact Number"
												placeholder={`e.g. ${PhoneNumberHelperText}`}
											/>
										}
									/>
									{/* End of Field */}
								</Box>

								{/* Start of Button Group */}
								<ModalFooter
									label="Edit"
									disabled={formik.isSubmitting}
									handleSuccess={() => {}}
									handleClose={handleClose}
								/>
								{/* End of Button Group */}
							</Box>
						</Form>
					);
				}}
			</Formik>
		</TemplateModal>
	);
}

EditModal.propTypes = {
	openState: PropTypes.bool,
	handleClose: PropTypes.func,
	payload: PropTypes.object,
};
