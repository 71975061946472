import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleOutline } from '@mui/icons-material';
import TemplateModal from './common/Template';
import { Box, Typography, Divider, Button } from '@mui/material';

/**
 * This serves as a success modal for actions
 * taken by another modal.
 *
 * @param { bool } openState    Indicates if a modal is opened or not
 * @param { func } handleClose  Handles how a modal should be closed
 * @param { String } message    The message to be displayed
 *
 * @returns { Modal } A ready-to-use modal
 */
export default function SuccessModal({ openState, handleClose, message }) {
	return (
		<TemplateModal
			icon={<CheckCircleOutline />}
			iconColor="var(--green)"
			title="Success!"
			openState={openState}
			handleClose={handleClose}
		>
			{/* Start of Message */}
			<Box
				sx={{
					p: '2rem',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Typography
					sx={{
						color: 'var(--gray)',
						textAlign: 'center',
						fontSize: '1.25rem',
					}}
				>
					{message}
				</Typography>
			</Box>
			{/* End of Message */}

			{/* Start of Footer */}
			<Divider light />
			<Box sx={{ p: '2rem' }}>
				<Button
					variant="contained"
					color="secondary"
					fullWidth
					onClick={handleClose}
				>
					Ok
				</Button>
			</Box>
			{/* End of Footer */}
		</TemplateModal>
	);
}

SuccessModal.propTypes = {
	openState: PropTypes.bool,
	handleClose: PropTypes.func,
	message: PropTypes.string,
};
