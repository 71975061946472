import React from 'react';
import Template from './Template';
import AccommOwnerService from '../../services/owner.service';
import AccommodationCard from '../../components/AccommodationCard';
import accomm_place from '../../assets/images/accomm_place.jpg';
import Page from '../../components/common/Page';
import UserService from '../../services/user.service';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useLocation } from 'react-router-dom';
import FavoriteService from '../../services/favorite.service';
import { Tab, Box, CircularProgress } from '@mui/material';

export default function OwnerProfile() {
	const { state } = useLocation();
	const userId = state.id;

	// Handle going back/next pages.
	const [page, setPage] = React.useState(1);
	const [pages, setPages] = React.useState(1);
	const handleChangePage = (event, value) => {
		setPage(value);
	};

	// Handle the change of tabs.
	const [tab, setTab] = React.useState(1);
	const handleChange = (event, newTab) => {
		setTab(newTab);
		setPage(1);
	};

	// Get the user's details.
	const [userDetails, setUserDetails] = React.useState({});
	const getUser = async () => {
		const service = new UserService();
		try {
			const res = await service.userDetails(userId);
			setUserDetails(res);
		} catch (err) {
			console.log(err);
		}
	};

	// Get all accommodations.
	const [accomm, setAccomm] = React.useState([]);
	const getAllAccomm = async () => {
		const service = new AccommOwnerService();
		try {
			const res = await service.allOwnerAccommodations(userId, page);
			setAccomm(res.accommodation);
			setPages(res.pages);
			console.dir(res);
		} catch (err) {
			console.log(err);
		}
	};

	// Get the favorite accommodations.
	const [favorites, setFavorites] = React.useState([]);
	const getFavorites = async (userId) => {
		const service = new FavoriteService();
		try {
			const res = await service.getFavorites(userId);
			setFavorites(res.data);
		} catch (err) {
			console.log(err);
		}
	};

	// Handles the loading process.
	const [isFetching, setFetchingStatus] = React.useState(false);
	React.useEffect(() => {
		setFetchingStatus(true);
		getFavorites(userId);
		getUser();
		getAllAccomm();
		setTimeout(() => {
			setFetchingStatus(false);
		}, 1000);
	}, [page]);

	return (
		<Template user={userDetails}>
			{/* Start of Tabs */}
			<TabContext value={tab}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleChange}>
						<Tab label="Owned" value={1} />
						<Tab label="Archived" value={2} />
						<Tab label="Favorites" value={3} />
					</TabList>
				</Box>

				{/* Start of Tab Content */}
				{/* Owned Accommodations */}
				<TabPanel value={1}>
					<Box
						sx={{
							mt: '2rem',
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							gap: '3rem',
							mb: '6rem',
						}}
					>
						{isFetching ? (
							<Box
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<CircularProgress
									size={40}
									sx={{ color: 'gray' }}
									style={{ paddingTop: '100px', paddingBottom: '100px' }}
								/>
							</Box>
						) : (
							accomm.map((entry, i) => {
								return (
									!entry.isArchived && (
										<AccommodationCard
											key={i}
											thumbnail={entry.pictures[0] || accomm_place}
											name={entry.accommodationName}
											location={entry.location}
											type={entry.type}
											minPrice={entry.price[0]}
											rating={entry.rating}
											id={entry._id}
											ownerId={entry.ownerId}
										/>
									)
								);
							})
						)}
					</Box>

					{/* Pagination */}
					<Page pages={pages} handleChangePage={handleChangePage} />
				</TabPanel>

				<TabPanel value={2}>
					<Box
						sx={{
							mt: '2rem',
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							gap: '3rem',
							mb: '6rem',
						}}
					>
						{isFetching ? (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<CircularProgress
									size={40}
									sx={{ color: 'gray' }}
									style={{ paddingTop: '100px', paddingBottom: '100px' }}
								/>
							</div>
						) : (
							accomm.map((entry, i) => {
								return (
									entry.isArchived && (
										<AccommodationCard
											key={i}
											thumbnail={entry.pictures[0] || accomm_place}
											name={entry.accommodationName}
											location={entry.location}
											type={entry.type}
											minPrice={entry.price[0]}
											rating={entry.rating || 'N/A'}
											id={entry._id}
											ownerId={entry.ownerId}
										/>
									)
								);
							})
						)}
					</Box>

					{/* Pagination */}
					<Page pages={pages} handleChangePage={handleChangePage} />
				</TabPanel>

				{/* Start of Favorites */}
				<TabPanel value={3}>
					<Box
						sx={{
							mt: '2rem',
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							gap: '3rem',
							mb: '6rem',
						}}
					>
						{favorites.map((entry, i) => {
							return (
								!entry.isArchived && (
									<AccommodationCard
										key={i}
										thumbnail={entry.pictures[0] || accomm_place}
										name={entry.accommodationName}
										location={entry.location}
										type={entry.type}
										minPrice={entry.price[0]}
										rating={entry.rating || 'N/A'}
										id={entry._id}
										ownerId={entry.ownerId}
									/>
								)
							);
						})}
					</Box>
				</TabPanel>
				{/* End of Favorites */}
				{/* End of Tab Content */}
			</TabContext>
			{/* End of Tabs */}
		</Template>
	);
}
