import * as yup from "yup";
import { RequiredError } from "../errors/schema.errors";

/**
 * Involves the body of the report.
 *
 * @returns { Object }  Schema of the report form
 */
const reportSchema = yup.object().shape({
  description: yup.string().required(RequiredError("report")),
});

/**
 * Contains the initial values of the form.
 *
 * @returns { Object }  Contains the initial values
 */
const initialValues = {
  description: "",
};

export { reportSchema, initialValues };
