import React from 'react';
import { PropTypes } from 'prop-types';
import Favorite from '@mui/icons-material/Favorite';
import { FavoriteButtonTheme } from '../../context/ButtonContext';
import { IconButton, ThemeProvider } from '@mui/material';
import FavoriteService from '../../services/favorite.service';
import toast from 'react-hot-toast';

/**
 * Adds an accommodation place to a user's list of
 * favorite places.
 *
 * @param { bool } isUnregistered The current auth status of the user
 * @param { bool } isPressed      Checks if the button is clicked or not
 *
 * @returns { IconButton }  A pink button with a heart as its label
 */
export default function FavoriteButton({
	isUnregistered,
	isPressed,
	userID,
	accomID,
}) {
	// Function that calls favorite service to pass request
	const addFave = async (userID, accomID) => {
		const favoriteService = new FavoriteService();

		try {
			const res = await favoriteService.addFavorite(userID, accomID);
			if (res.success) {
				toast.success(res.message, { duration: 5000 });
			} else {
				toast.error(res.message, { duration: 5000 });
			}
		} catch (err) {
			console.log(err);
		}
	};

	// Function call when button is pressed
	// isPressed ? addFave(userID, accomID) : null;
	return (
		<ThemeProvider theme={FavoriteButtonTheme}>
			<IconButton
				onClick={() => addFave(userID, accomID)}
				disabled={isUnregistered}
				/**
				 * If the button is pressed/active, display active state.
				 * Else, display the button's default state.
				 */
				sx={{
					height: '44px',
					width: '44px',
					color: !isPressed ? 'primaryColor.main' : 'secondaryColor.main',
					bgcolor: !isPressed ? 'primaryBGColor.main' : 'secondaryBGColor.main',
					':hover': !isPressed
						? { bgcolor: 'primaryColor.main', color: 'white' }
						: { bgcolor: '#f7cad1', color: '#e23e57' },
					':disabled': { bgcolor: '#e0e0e0', color: '#a6a6a6' },
				}}
			>
				<Favorite />
			</IconButton>
		</ThemeProvider>
	);
}

FavoriteButton.propTypes = {
	isUnregistered: PropTypes.bool,
	isPressed: PropTypes.bool,
	userID: PropTypes.string,
	accomID: PropTypes.string,
};
