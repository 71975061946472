import { USER } from "./constants";
import { FileSizeError, FileTypeError } from "./errors/schema.errors";
import { MAX_FILE_SIZE } from "./constants";

/**
 * Get the role of the user based
 * on the user type.
 *
 * @returns { String }  The name of the user's role
 */
export const getProfileLink = (userType) => {
  switch (userType) {
    case USER.TYPE.ADMIN:
      return "admin";
    case USER.TYPE.OWNER:
      return "owner";
    case USER.TYPE.REGISTERED:
      return "user";
    default:
      return null;
  }
};

/**
 * Check if the size of a file does not exceed
 * beyond the specified size.
 *
 * @returns { any }  An assertion if the test suceeds/fails
 */
export const validateFileSize = () => {
  return this.test(
    "file_size",
    FileSizeError,
    (value) => value && value.size <= MAX_FILE_SIZE
  );
};

/**
 * Check if the file type is supported.
 *
 * @param { Array } typeArr   An array of supported file types
 *
 * @returns { any } An assertion if the test succeeds/fails
 */
export const validateFileType = (typeArr) => {
  return this.test(
    "file_type",
    FileTypeError,
    (value) => value && typeArr.includes(value.type)
  );
};
