import React from "react";
import PropTypes from "prop-types";
import no_picture from "../assets/images/no_picture.png";
import { Close } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { USER } from "../utils/constants";

/**
 * Render a container dedicated to a specific notification.
 *
 * @param { Object } username       The user object from database
 * @param { String } content        The content of the notification
 * @param { Number } role           The role of the user
 *
 * @returns { Element } A ready-to-use container for notifications
 */
export default function NotifItem({ username, content, role }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
      <Button sx={{ padding: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            maxWidth: "750px",
            minWidth: "200px",
          }}
        >
          {/* Start of Icon */}
          <img
            src={no_picture}
            alt="user icon"
            style={{
              marginRight: 2,
              width: "48px",
              height: "48px",
              borderRadius: "100%",
            }}
          />
          {/* End of Icon */}

          {/* Start of Container */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              textAlign: "left",
              ml: "1.25rem",
            }}
          >
            {/* Start of Header */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.015rem",
                mb: "-0.2rem",
              }}
            >
              {role === USER.TYPE.ADMIN ? (
                <React.Fragment>
                  <Typography
                    sx={{ fontWeight: "bold", marginRight: 1, color: "black" }}
                  >
                    {username.fullName["firstName"] +
                      " " +
                      username.fullName["lastName"]}
                  </Typography>
                  <Typography
                    sx={{
                      textTransform: "none",
                      marginRight: 1,
                      color: "black",
                    }}
                  >
                    submitted a report.
                  </Typography>
                </React.Fragment>
              ) : role === USER.TYPE.OWNER ? (
                <React.Fragment>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      marginRight: 1,
                      color: "black",
                    }}
                  >
                    Your accommodation has a report.
                  </Typography>
                </React.Fragment>
              ) : role === USER.TYPE.REGISTERED ? (
                <React.Fragment>
                  <Typography
                    sx={{
                      textTransform: "none",
                      fontWeight: "bold",
                      marginRight: 1,
                      color: "black",
                    }}
                  >
                    Admin action.
                  </Typography>
                </React.Fragment>
              ) : null}
            </Box>
            {/* End of Header */}

            {/* Start of Content */}
            <Typography
              variant="body 1"
              sx={{ color: "var(--gray)", textTransform: "none" }}
            >
              {content}
            </Typography>
            {/* End of Content */}
          </Box>
          {/* End of Container */}
        </Box>

        {/* Start of Close Icon */}
        <Button sx={{ ml: "1.5rem" }}>
          <Close sx={{ color: "var(--gray)", fontSize: "1rem" }} />
        </Button>
        {/* End of Close Icon */}
      </Button>
    </Box>
  );
}

NotifItem.propTypes = {
  username: PropTypes.object,
  content: PropTypes.string,
  role: PropTypes.number,
};
