import React from 'react';
import { Box, Typography, Button, FormHelperText } from '@mui/material';
import { UploadRounded } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { initialValues, ownerSchema } from '../../utils/schema/owner.schema';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import TemplateModal from './common/Template';
import ModalFooter from './common/ModalFooter';
import FieldContainer from '../form/FieldContainer';
import useAuth from '../../hooks/useAuth';
import AccommOwnerService from '../../services/owner.service';

/**
 * This contains the form that is used for
 * users who wish to be accommodation owners.
 *
 * @param { bool } openState      Checks if a modal should be opened/closed
 * @param { func } handleClose    Handles the closing of the modal
 * @param { func } handleSuccess  Executes if the user confirms the action
 *
 * @returns { Modal } A ready-to-use modal with the form
 */
export default function OwnerFormModal({
	openState,
	handleClose,
	handleSuccess,
}) {
	const [files, setFiles] = React.useState([]);
	const ref = React.useRef(null);

	// Set the files as form data after uploading.
	const onDrop = React.useCallback(
		(acceptedFiles) => {
			ref.current.setFieldValue('files', acceptedFiles && acceptedFiles[0]);
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
		[files]
	);

	// Remove the current files.
	const removeFiles = () => {
		ref.current.setFieldValue('files', []);
		setFiles([]);
	};

	const { getRootProps, getInputProps, open } = useDropzone({
		// Disable click and keydown behavior
		onDrop,
		noClick: true,
		noKeyboard: true,
	});

	const filePath = files.map((file) => file.path);
	const { auth } = useAuth();

	/**
	 * Submit a user's application to become an
	 * accommodation owner.
	 *
	 * @param { FormData } formData   Contains the email & documents
	 */
	const submitApplication = async (formData) => {
		const service = new AccommOwnerService();
		try {
			await service.applyOwner(formData);
		} catch (err) {
			console.log(err);
		}
	};

	// Handle the overall submission.
	const handleSubmit = (files) => {
		// Initialize the form data.
		const formData = new FormData();
		formData.append('email', auth.email);
		formData.append('files', files);

		// Submit the application.
		submitApplication(formData);
		handleSuccess();
	};

	return (
		<TemplateModal
			icon={<KeyboardCommandKeyIcon />}
			title="Become an Owner"
			openState={openState}
			handleClose={() => {
				removeFiles();
				handleClose();
			}}
		>
			<Formik
				innerRef={ref}
				initialValues={initialValues}
				onSubmit={async (values) => {
					handleSubmit(values.files);
				}}
				validationSchema={ownerSchema}
			>
				{(formik) => {
					return (
						<Form>
							{/* Start of Description */}
							<Typography
								variant="body1"
								sx={{
									color: 'var(--gray)',
									px: '2rem',
									pt: '1.25rem',
								}}
							>
								Do you currently own a place where students can stay? <br />
								Apply now to become an owner!
							</Typography>
							{/* End of Description */}

							{/* Start of Modal Body */}
							<Box
								sx={{
									p: '2rem',
									display: 'flex',
									flexDirection: 'column',
									gap: '1.5rem',
								}}
							>
								{/* Start of Document */}
								<FieldContainer
									label="Document"
									component={
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											{/* Start of Field */}
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													gap: '0rem',
												}}
											>
												<div {...getRootProps({ className: 'dropzone' })}>
													<input {...getInputProps()} />
													<Button
														onClick={open}
														variant="outlined"
														sx={{ whiteSpace: 'nowrap', marginRight: 3 }}
														startIcon={<UploadRounded />}
													>
														Upload
													</Button>
												</div>
												<Typography
													variant="body2"
													sx={{ color: 'var(--gray)' }}
												>
													{filePath.length > 0 ? filePath : 'No file uploaded'}
												</Typography>
											</Box>
											{/* End of Field */}

											{/* Start of Error Message */}
											<Box sx={{ ml: '1rem', mt: '0.2rem' }}>
												<FormHelperText>
													<Typography color="error" variant="caption">
														{formik.errors.files || undefined}
													</Typography>
												</FormHelperText>
											</Box>
											{/* End of Error Message */}
										</Box>
									}
								/>
								{/* End of Document */}

								{/* Start of Note */}
								<Typography variant="body2" sx={{ color: 'var(--gray)' }}>
									{/* Start of Description */}
									<b style={{ color: 'var(--red)' }}>*Note: </b>
									Upload any of the following documents:
									{/* End of Description */}
									{/* Start of Requirements */}
									<ul>
										<li>DTI or SEC Registration Form</li>
										<li>Barangay Clearance</li>
										<li>Contact of Lease</li>
										<li>Community Tax Certificate</li>
										<li>Occupancy Permit</li>
									</ul>
									{/* End of Requirements */}
								</Typography>
								{/* End of Note */}
							</Box>
							{/* End of Modal Body */}

							{/* Start of Modal Footer */}
							<ModalFooter
								label="Apply"
								disabled={formik.isSubmitting}
								handleSuccess={() => {}}
								handleClose={() => {
									removeFiles();
									handleClose();
								}}
							/>
							{/* End of Modal Footer */}
						</Form>
					);
				}}
			</Formik>
		</TemplateModal>
	);
}

OwnerFormModal.propTypes = {
	openState: PropTypes.bool,
	handleClose: PropTypes.func,
	handleSuccess: PropTypes.func,
};
