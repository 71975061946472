import React from 'react';
import TemplateModal from './common/Template';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalFooter from './common/ModalFooter';
import { Typography, Box } from '@mui/material';

/**
 * Displays a confirmation modal before deleting
 * the accommodation.
 *
 * @param { bool } openState        Indicates if a modal is open or not
 * @param { func } handleClose      Closes the modal
 * @param { func } handleOpenSubmit Handles the success function
 * @param { String } accomName      Name of the accommodation
 *
 * @returns { Element } A ready-to-use modal for deletion
 */
export default function DeleteAccommModal({
	openState,
	handleClose,
	handleOpenSubmit,
	accomName,
}) {
	return (
		<TemplateModal
			icon={<DeleteIcon />}
			iconColor="#dc2626"
			title="Delete"
			openState={openState}
			handleClose={handleClose}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					textAlign: 'center',
					py: '1.75rem',
				}}
			>
				<Typography
					variant="body1"
					color="neutral"
					sx={{ fontSize: '1.25rem' }}
				>
					Are you sure you want to delete <b>{accomName}?</b>
				</Typography>
			</Box>

			{/* Start of Button Group */}
			<ModalFooter
				label="Delete"
				handleSuccess={handleOpenSubmit}
				handleClose={handleClose}
				palette="error"
			/>
			{/* End of Button Group */}
		</TemplateModal>
	);
}

DeleteAccommModal.propTypes = {
	openState: PropTypes.bool,
	handleClose: PropTypes.func,
	handleOpenSubmit: PropTypes.func,
	accomName: PropTypes.string,
};
