import React from "react";
import PropTypes from "prop-types";
import { Modal, Box, Typography, Divider, Icon } from "@mui/material";

/**
 * Serves as the standard layout for all modals.
 *
 * @param { Icon } icon         The primary icon of the modal
 * @param { String } iconColor  The color of the icon (default: RED)
 * @param { String } title      The name of the modal
 * @param { Object } children   The content inside the modal
 * @param { bool } openState    Indicates if a modal is open or not
 * @param { func } handleClose  Handles the closing of the modal
 *
 * @returns { Element } A ready-to-use standard modal
 */
export default function TemplateModal({
	icon,
	iconColor = "var(--red)",
	title,
	children,
	openState,
	handleClose,
}) {
	return (
		<Modal
			open={openState}
			onClose={handleClose}
			sx={{
				alignItems: "center",
				justifyContent: "center",
				display: "flex",
			}}
		>
			<Box
				sx={{
					backgroundColor: "var(--white)",
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					boxShadow: 24,
					borderRadius: "0.5rem",
				}}
			>
				{/* Start of Header */}
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						pl: "2rem",
						pr: "8rem",
						py: "1.75rem",
						width: "24rem",
					}}
				>
					<Typography
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: "1rem",
							color: "grey.900",
							fontSize: "2rem",
							fontWeight: 600,
						}}
					>
						{/* Icon */}
						<Icon
							sx={{
								backgroundColor: iconColor,
								color: "var(--white)",
								p: "0.5rem",
								borderRadius: "10%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							{icon}
						</Icon>

						{/* Title */}
						{title}
					</Typography>
				</Box>
				<Divider light />
				{/* End of Header */}

				{/* Start of Body */}
				{children}
				{/* End of Body */}
			</Box>
		</Modal>
	);
}

TemplateModal.propTypes = {
	icon: PropTypes.element,
	iconColor: PropTypes.string,
	title: PropTypes.string,
	children: PropTypes.element,
	openState: PropTypes.bool,
	handleClose: PropTypes.func,
};
