import React from 'react';
import { Avatar, Typography, Box, Rating } from '@mui/material';
import PropTypes from 'prop-types';
import profileIcon from '../../assets/images/no_picture.png';
import ShowReviewModal from '../modals/ShowReview';

/**
 * Renders a section for user review with the
 * inclusion of an avatar (or picture).
 *
 * @param { array } review        An array of objects containing details of accommodations
 * @param { boolean } isProfile   True if the user is viewing 1 review, false if user is viewing all reviews
 *
 * @returns { div } A section containing a user review with an avatar/picture
 */
export default function ReviewAccommProfile({ review, isProfile }) {
	return (
		<div>
			{/* Start of rating box */}
			{review.map((detail, i) => (
				<Box
					sx={{
						display: 'block',
						justifyContent: 'space-between',
						marginTop: 4,
						marginBottom: 7,
						Height: 188,
					}}
					key={i}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<Avatar
								src={detail.picture || profileIcon}
								sx={{
									width: 56,
									height: 56,
									marginTop: -1,
									marginRight: 1.5,
								}}
							/>
							<Box
								sx={{
									display: 'block',
									justifyContent: 'space-between',
									ml: '0.5rem',
								}}
							>
								<Typography
									variant="h6"
									sx={{
										display: 'block',
										justifyContent: 'left',
									}}
								>
									{detail.userName}
								</Typography>
								<Typography
									variant="body1"
									color="var(--gray)"
									sx={{
										fontWeight: 'medium',
										marginBottom: 1,
										justifyContent: 'left',
									}}
								>
									{new Date(detail.createdAt).toISOString().substring(0, 10)}
								</Typography>
							</Box>
						</Box>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'left',
							}}
						>
							<Rating name="read-only" value={detail.rating} readOnly />
						</Box>
					</Box>
					<Typography
						variant="body1"
						sx={{
							marginBottom: 1,
							marginTop: 2,
							justifyContent: 'left',
						}}
					>
						{detail.desc.length > 198 && isProfile == false
							? detail.desc.slice(0, 147) + '...'
							: detail.desc}
					</Typography>
					<Typography
						variant="body1"
						color="var(--gray)"
						sx={{
							justifyContent: 'left',
							textDecoration: 'underline',
						}}
					>
						{detail.desc.length > 198 && isProfile == false && (
							<ShowReviewModal review={detail} />
						)}
					</Typography>
					{/* End of rating box */}
				</Box>
			))}
		</div>
	);
}

ReviewAccommProfile.propTypes = {
	review: PropTypes.arrayOf(
		PropTypes.shape({
			userName: PropTypes.string,
			rating: PropTypes.number,
			date: PropTypes.string,
			desc: PropTypes.string,
		})
	),
	isProfile: PropTypes.bool,
};
