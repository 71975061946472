const RequiredError = (fieldname) => {
	return `The field '${fieldname}' is required.`;
};

const FormatError = (format) => {
	return `The input should follow the format: ${format}.`;
};

const TypeError = (type) => {
	return `The input should be a/an ${type}.`;
};

const FileSizeError = (size, unit) => {
	return `The file size can only be up to ${size} ${unit.toUpperCase()}`;
};

const FileTypeError = (typeArr) => {
	return `The file should only be of the types: ${typeArr.join(', ')}`;
};

const EmailFormatError = () => {
	return `This is not a valid e-mail address.`;
};

const EmptyNumberError = () => {
	return 'This field should be greater than 0.';
};

export {
	RequiredError,
	FormatError,
	TypeError,
	FileSizeError,
	FileTypeError,
	EmailFormatError,
	EmptyNumberError,
};
