import React, { useState } from "react";
import { Typography, Grid, Box, Button } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddButton from "../../../components/button/AddButton";
import RemoveButton from "../../../components/button/RemoveButton";
import FormLabel from "../../../components/form/FormLabel";
import SelectField from "../../../components/form/fields/SelectField";
import InputField from "../../../components/form/fields/InputField";
import { useFormikContext } from "formik";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";

/**
 * Renders the essential fields of the accommodation
 * place in the form.
 *
 * @returns { Element } Layout containing fields of the section
 */
export default function BasicInformation({ files, setFiles }) {
	const accommTypes = [
		{ name: "Hotel", value: "hotel" },
		{ name: "Apartment", value: "apartment" },
		{ name: "Bed Space", value: "bed space" },
		{ name: "Dormitory", value: "dormitory" },
		{ name: "Transient", value: "transient" },
	];

	const tenants = [
		{ name: "Male", value: "male" },
		{ name: "Female", value: "female" },
		{ name: "Coed", value: "coed" },
	];

	const towns = [
		{ name: "Batong Malake", value: "Batong Malake" },
		{ name: "San Antonio", value: "San Antonio" },
		{ name: "Mayondon", value: "Mayondon" },
		{ name: "Anos", value: "Anos" },
	];

	const [capacity, setCapacity] = useState(0);
	const { setFieldValue } = useFormikContext();

	/**
	 * Used for image upload
	 */
	const { getInputProps } = useDropzone({
		accept: {
			"image/*": [],
		},
		onDrop: (acceptedFiles) => {
			// Set the file in the form.
			setFieldValue("thumbnail", acceptedFiles && acceptedFiles[0]);

			const updatedFiles = acceptedFiles.map((file) => {
				const reader = new FileReader();
				reader.onload = () => {
					const dataURL = reader.result;
					file.preview = dataURL;
				};
				reader.readAsDataURL(file);

				return file;
			});

			setFiles(updatedFiles); // Call the setFiles callback
		},
	});

	/**
	 * Increases the capacity (or pax)
	 * in the form.
	 */
	const addCapacity = () => {
		setFieldValue("pax", capacity + 1);
		setCapacity(capacity + 1);
	};

	/**
	 * Decreases the capacity (or pax)
	 * in the form.
	 */
	const subtractCapacity = () => {
		const newCapacity = capacity > 0 ? capacity - 1 : 0;
		setFieldValue("pax", newCapacity);
		setCapacity(newCapacity);
	};

	return (
		<React.Fragment>
			{/* Start of Space */}
			<Box sx={{ mt: 8 }}></Box>
			{/* End of Space */}

			{/* Start of Section */}
			<Grid container spacing={4}>
				{/* Name */}
				<Grid item xs={6}>
					<FormLabel name="accommodationName" label="Name" />
				</Grid>

				{/* Type */}
				<Grid item xs={6}>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Typography variant="h6" sx={{ minWidth: 180 }}>
							Type
						</Typography>
						<SelectField name="type" label="Type" data={accommTypes} />
					</Box>
				</Grid>

				{/* Description */}
				<Grid item xs={12}>
					<FormLabel
						name="description"
						label="Description"
						placeholder="Describe the accommodation place."
						multiline={true}
						rows={4}
					/>
				</Grid>

				{/* Address - Thumbnail */}
				<Grid item xs={6}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "32px",
						}}
					>
						{/* Address */}
						<FormLabel
							name="completeAddress"
							label="Address"
							placeholder="Street Name, Building, House No."
						/>

						{/* Price Range */}
						<Box
							sx={{
								display: "flex",
								alignItems: "baseline",
								justifyContent: "space-between",
							}}
						>
							<Typography variant="h6" sx={{ minWidth: 180 }}>
								Price Range
							</Typography>
							<Box
								sx={{
									display: "flex",
									alignItems: "baseline",
									justifyContent: "space-between",
									gap: "12px",
								}}
							>
								<InputField name="minPrice" label="Minimum" />
								<Typography variant="p"> — </Typography>
								<InputField name="maxPrice" label="Maximum" />
							</Box>
						</Box>

						{/* Tenants */}
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<Typography variant="h6" sx={{ minWidth: 180 }}>
								Tenants
							</Typography>
							<SelectField name="tenants" label="Tenants" data={tenants} />
						</Box>

						{/* Thumbnail */}
						<Box
							sx={{
								display: "flex",
								alignItems: "baseline",
							}}
						>
							<Typography variant="h6" sx={{ minWidth: 180 }}>
								Thumbnail
							</Typography>

							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "16px",
								}}
							>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										gap: "8px",
									}}
								>
									<Button variant="outlined" component="label">
										<input {...getInputProps()} />
										<FileUploadOutlinedIcon />
										<b>Upload</b>
									</Button>
									<Typography>
										{/* {files ? files && files[0].path : "No file uploaded"} */}
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</Grid>

				{/* Town - Capacity */}
				<Grid item xs={6}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "32px",
						}}
					>
						{/* Town */}
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<Typography variant="h6" sx={{ minWidth: 180 }}>
								Town
							</Typography>
							<SelectField name="location" label="Town" data={towns} />
						</Box>

						{/* Minimum Stay */}
						<FormLabel
							name="minimumStay"
							label="Minimum Stay"
							placeholder="No. of Months"
						/>

						{/* Capacity */}
						<Box
							sx={{
								display: "flex",
								alignItems: "baseline",
							}}
						>
							<Typography variant="h6" sx={{ minWidth: 180 }}>
								Capacity
							</Typography>

							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									gap: 2,
								}}
							>
								<RemoveButton handleClick={subtractCapacity} />
								<InputField
									name="pax"
									label=""
									disabled={true}
									value={capacity}
								/>
								<AddButton handleClick={addCapacity} />
							</Box>
						</Box>
					</Box>
				</Grid>
			</Grid>
			{/* End of Section */}

			{/* This is where the image upload previous will be shown */}
			<Box
				sx={{
					display: "inline-flex",
					flexDirection: "row",
					flexWrap: "wrap",
					width: "100%",
				}}
			>
				{/* TODO: Fix preview of files when uploaded. They only show when the page is rerendered. */}
				{files.map((file) => {
					return (
						<Box
							sx={{
								display: "inline-flex",
								width: "30%",
								height: "auto",
								boxSizing: "border-box",
								border: "solid gray 1px",
								borderRadius: "5px",
								margin: "5px",
							}}
							key={file.name}
						>
							<Box
								sx={{
									display: "flex",
									minWidth: 0,
									overflow: "hidden",
									padding: "5px",
								}}
							>
								<img
									src={file.preview}
									style={{
										display: "block",
										maxWidth: "100%",
										height: "auto",
									}}
								/>
							</Box>
						</Box>
					);
				})}
			</Box>

			{/* Start of Space */}
			<Box sx={{ mt: 8 }}></Box>
			{/* End of Space */}
		</React.Fragment>
	);
}

BasicInformation.propTypes = {
	files: PropTypes.arr,
	setFiles: PropTypes.func,
};
