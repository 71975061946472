import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { Wizard, WizardStep } from "../../components/form/Wizard";
import {
  addInfoSchema,
  basicInfoSchema,
  initialValues,
} from "../../utils/schema/accomm.schema";
import BasicInformation from "./sections/BasicInformation";
import AddInformation from "./sections/AddInformation";
import Preview from "./sections/Preview";
import AddHomeRoundedIcon from "@mui/icons-material/AddHomeRounded";
import PropTypes from "prop-types";
import AccommService from "../../services/accommodation.service";
import useAuth from "../../hooks/useAuth";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

/**
 * Renders a form to add/edit an accommodation to the system.
 * Only accommodation owners are permitted to add/edit an accommodation.
 *
 * @param { bool } isEdit Indicates if the accommodation is added/edited
 *
 * @returns { Element } The layout of the accommodation form
 */
export default function Form({ isEdit }) {
  const sections = ["Basic Information", "Additional Information", "Preview"];
  const schema = [basicInfoSchema, addInfoSchema, undefined];
  const [files, setFiles] = useState([]);
  const [accomDetails, setAccomDetails] = useState(null); // to store accommodation details

  // Get the ID from the profile.
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const accomId = isEdit && urlParams.get("id");

  // Get ID of currently logged in user
  const { auth } = useAuth();
  const navigate = useNavigate();

  /* eslint-disable react/jsx-key */
  const sectionForms = [
    <BasicInformation files={files} setFiles={setFiles} />,
    <AddInformation />,
    <Preview files={files} />,
  ];
  /* eslint-enable react/jsx-key */

  // const navigate = useNavigate();

  // fetch accommodation details and assign as initial values of the form
  const getAccomDetails = async (accomId) => {
    const service = new AccommService();
    try {
      const accomRes = await service.viewAccommodation(accomId);
      if (accomRes === undefined) throw new Error();
      setAccomDetails(accomRes);
      initialValues.accommodationName = accomRes.accommodationName;

      // Convert value of type property to string
      if (accomRes.type === 0) {
        initialValues.type = "hotel";
      } else if (accomRes.type === 1) {
        initialValues.type = "apartment";
      } else if (accomRes.type === 2) {
        initialValues.type = "bed space";
      } else if (accomRes.type === 3) {
        initialValues.type = "dormitory";
      } else if (accomRes.type === 4) {
        initialValues.type = "transient";
      }

      initialValues.description = accomRes.description;
      initialValues.completeAddress = accomRes.completeAddress;

      // Convert value of tenants property to string
      if (accomRes.location === "one") {
        initialValues.location = "Option 1";
      } else if (accomRes.location === "two") {
        initialValues.location = "Option 2";
      } else if (accomRes.location === "Mayondon") {
        initialValues.location = "Mayondon";
      }

      initialValues.minPrice = accomRes.price[0];
      initialValues.maxPrice = accomRes.price[1];
      initialValues.minimumStay = accomRes.minimumStay;

      // Convert value of tenants property to string
      if (accomRes.tenants === 0) {
        initialValues.tenants = "male";
      } else if (accomRes.tenants === 1) {
        initialValues.tenants = "female";
      } else if (accomRes.tenants === 2) {
        initialValues.tenants = "coed";
      }

      initialValues.pax = accomRes.pax;
      initialValues.thumbnail = accomRes.pictures[0];
      initialValues.billInclusions = accomRes.billInclusions;
      initialValues.amenities = accomRes.amenities;
      initialValues.curfew = accomRes.curfew;
      initialValues.visitorsAllowed = accomRes.visitorsAllowed;
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (values) => {
    const service = new AccommService();

    try {
      // Create separate object for accom details so values are not overwritten.
      // Remove billInclusions, amenities, min and max price properties in accomFormDetails object for the form data. Reference: https://stackoverflow.com/a/40493600
      const {
        billInclusions,
        amenities,
        minPrice, // eslint-disable-line no-unused-vars
        maxPrice, // eslint-disable-line no-unused-vars
        thumbnail, // eslint-disable-line no-unused-vars
        ...accomFormDetails
      } = values;

      // Add ownerId property to accomFormDetails
      accomFormDetails.ownerId = auth && auth.id;

      // Convert value of type property to number
      if (accomFormDetails.type === "hotel") {
        accomFormDetails.type = 0;
      } else if (accomFormDetails.type === "apartment") {
        accomFormDetails.type = 1;
      } else if (accomFormDetails.type === "bed space") {
        accomFormDetails.type = 2;
      } else if (accomFormDetails.type === "dormitory") {
        accomFormDetails.type = 3;
      } else if (accomFormDetails.type === "transient") {
        accomFormDetails.type = 4;
      }

      // Convert value of tenants property to number
      if (accomFormDetails.tenants === "male") {
        accomFormDetails.tenants = 0;
      } else if (accomFormDetails.tenants === "female") {
        accomFormDetails.tenants = 1;
      } else if (accomFormDetails.tenants === "coed") {
        accomFormDetails.tenants = 2;
      }

      // Convert value of tenants property to string
      if (accomFormDetails.visitorsAllowed === "Not Allowed") {
        accomFormDetails.visitorsAllowed = "not allowed";
      } else if (accomFormDetails.visitorsAllowed === "Allowed, No Overnight") {
        accomFormDetails.visitorsAllowed = "allowed, no overnight";
      } else if (accomFormDetails.visitorsAllowed === "Overnight Allowed") {
        accomFormDetails.visitorsAllowed = "overnight allowed";
      }

      // Create a new FormData object
      const accomFormData = new FormData();

      // Append the accommodation data to accomFormData (except for bill inclusions, amenities, and price)
      Object.entries(accomFormDetails).forEach(([key, value]) => {
        accomFormData.append(key, value);
      });

      // Append price, bill inclusions, and amenities to accomFormData separately
      accomFormData.append("price", values.minPrice);
      accomFormData.append("price", values.maxPrice);

      billInclusions.forEach((inclusion) => {
        accomFormData.append("billInclusions", inclusion);
      });

      amenities.forEach((amenity) => {
        accomFormData.append("amenities", amenity);
      });

      // Append files to accomFormData
      files.forEach((file) => {
        accomFormData.append("files", file);
      });

      const res = await service.addAccommodation(accomFormData);
      if(res.success){
        toast.success(res.message, {duration: 5000});
			} else {
				toast.error(res.message, {duration: 5000});
			}
      navigate('/');
    } catch (err) {
      console.log(err);
    }

    // navigate("../", { replace: true });
  };

  useEffect(() => {
    // Fetch accommodation details
    getAccomDetails(accomId);
    // console.log("accomDetails: ", accomDetails);
  }, [accomDetails]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={1}></Grid>

      <Grid item xs={10}>
        {/* Start of Heading */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}>
          <AddHomeRoundedIcon
            fontSize={"large"}
            style={{ color: "var(--red)" }}
          />
          <Typography variant="h4">
            {!isEdit ? "Add" : "Edit"} Accommodation
          </Typography>
        </Box>
        {/* End of Heading */}

        {/* Start of Space */}
        <Box sx={{ mt: 6 }}></Box>
        {/* End of Space */}

        {/* Start of Wizard */}
        <Wizard
          sectionNames={sections}
          initialValues={initialValues}
          handleSubmit={handleSubmit}>
          {sectionForms.map((form, i) => {
            return (
              <WizardStep key={i} validationSchema={schema[i]}>
                {form}
              </WizardStep>
            );
          })}
        </Wizard>
        {/* End of Wizard */}
      </Grid>

      <Grid item xs={1}></Grid>
    </Grid>
  );
}

Form.propTypes = {
  isEdit: PropTypes.bool,
};
