import React from 'react';
import PropTypes from 'prop-types';
import empty_design from '../../assets/images/empty_design.png';
import { Typography, Container, Grid } from '@mui/material';
/**
 *
 * @param { int } topPadding set paddingTop of component
 * @param { int } bottomPadding set paddingBottom of component
 * @param { string } dataType set 'datatype' being loaded
 *
 * @returns { EmptyMessage } component that is displayed if there is no data to be displayed
 */
export default function EmptyMessage(props) {
	return (
		<Container display="flex">
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				paddingTop={props.topPadding}
				paddingBottom={props.bottomPadding}
			>
				<Grid item xs marginBottom="10px">
					<img src={empty_design} style={{ width: 80, height: 80 }} />
				</Grid>
				<Grid item xs marginBottom="-20px">
					<Typography
						variant="h5"
						sx={{
							fontSize: 16,
							color: '#707070',
							fontWeight: 'Regular',
							mt: '1rem',
						}}
					>
						No {props.dataType} found at this time.
					</Typography>
				</Grid>
			</Grid>
		</Container>
	);
}

EmptyMessage.propTypes = {
	topPadding: PropTypes.number,
	bottomPadding: PropTypes.number,
	dataType: PropTypes.string,
};
