import React from "react";
import { Typography, Box } from "@mui/material";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";

/**
 * This serves as a placeholder if there is no
 * review yet from the accommodation place.
 *
 * @returns { Element } A ready-to-use placeholder if there are no reviews
 */
export default function NoReview() {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				pt: "1rem",
				pb: "2rem",
				gap: "0.75rem",
			}}
		>
			<PostAddOutlinedIcon
				sx={{
					color: "#bdbdbd",
					fontSize: "6rem",
				}}
			/>
			<Typography
				color="var(--gray)"
				sx={{
					textDecoration: "underline",
					fontSize: "1.2rem",
				}}
			>
				Leave a Review
			</Typography>
		</Box>
	);
}
