import React from 'react';
import stals_logo from '../../assets/images/stals_logo.png';
import { Search } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import LoginModal from '../modals/Login';
import { USER } from '../../utils/constants';
import OwnerForm from '../modals/OwnerForm';
import NotifPopover from '../NotifPopover';
import NotifItem from '../NotifItem';
import no_picture from '../../assets/images/no_picture.png';
import { getProfileLink } from '../../utils/helpers';
import useAuth from '../../hooks/useAuth';
import {
	Button,
	InputAdornment,
	TextField,
	Typography,
	Box,
} from '@mui/material';
import NotificationService from '../../services/notification.service';
import UserService from '../../services/user.service';
import SuccessModal from '../modals/Success';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';

/**
 * Includes the app logo, search bar, and the
 * identification of user type.
 *
 * @returns { Element } A ready-to-use Header component
 */
export default function Header() {
	const navigate = useNavigate();
	const { auth, setAuth } = useAuth();

	// Login Modal
	const [openLogin, setOpenLogin] = React.useState(false);
	const handleOpenLogin = () => setOpenLogin(true);
	const handleCloseLogin = () => setOpenLogin(false);

	// Owner Modal
	const [openOwner, setOpenOwner] = React.useState(false);
	const handleOpenOwner = () => setOpenOwner(true);
	const handleCloseOwner = () => setOpenOwner(false);

	// Success Modal
	const [openSuccess, setOpenSuccess] = React.useState(false);
	const handleOpenSuccess = () => setOpenSuccess(true);
	const handleCloseSuccess = () => {
		setOpenSuccess(false);
		handleCloseOwner();
	};

	// For Notifications
	const [userDetails, setUserDetails] = React.useState(null);
	const [notifArr, setNotifArr] = React.useState([]);

	// Fetch the user details.
	const getUserDetails = async (userId) => {
		const service = new UserService();
		try {
			const userRes = await service.userDetails(userId);
			if (userRes === undefined) throw new Error();
			setUserDetails(userRes);
		} catch (err) {
			console.log(err);
		}
	};

	// Fetch all notifications.
	const getNotifications = async (id) => {
		const service = new NotificationService();
		try {
			const notifRes = await service.getAllNotifications(id);
			if (notifRes === undefined) throw new Error();
			setNotifArr(notifRes);
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * Searches the accommodation place/s through
	 * a partial/full string.
	 */
	const search = () => {
		const searchValue = document.getElementById('search').value;
		if (searchValue)
			navigate({ pathname: '/', search: '?accommodationName=' + searchValue });
	};

	/**
	 * Get the currently logged user in
	 * the system.
	 */
	const getUser = async () => {
		const service = new UserService();
		try {
			// Get the respnse.
			const user = await service.getLoggedUser();

			// Set the user.
			setAuth({
				id: user._id,
				firstName: user.fullName.firstName,
				lastName: user.fullName.lastName,
				role: user.type + 1, // Increment by 1.
				contactNumber: user.contactNumber,
				email: user.email,
				picture: user.picture,
				favorites: user.favorites,
			});
		} catch (err) {
			console.log(err);
		}
	};

	// Handle the logout process.
	const handleLogout = async () => {
		const service = new UserService();
		try {
			// setTimeout(() => {
			// 	service.logout();
			// }, 1000);
			// window.location.reload(); // Reload current page.

			const res = await service.logout();

			console.log("in handle logout")
			
			if (res.data === 'out'){
				//window.location.href = "/"
				window.location.reload();
			}

		} catch (err) {
			console.log(err);
		}
	};

	// Retrieve data from the database (via backend) every render
	React.useEffect(() => {
		getUser();
		getNotifications(auth.id);
	}, []);

	// create the notifications list
	const notifications = [];
	for (var i = 0; i < notifArr.length; i++) {
		if (!userDetails) getUserDetails(notifArr[i].senderId); // get user details for name

		var temp = (
			<NotifItem
				key={i}
				username={userDetails}
				content={notifArr[i].body}
				role={auth.role}
			/>
		);

		notifications.push(temp);
	}

	return (
		<Box className="header">
			{/* Start of Logo */}
			<Link to="/">
				<img src={stals_logo} className="header_logo" />
			</Link>
			{/* End of Logo */}

			{/* Start of Search Field */}
			<TextField
				id="search"
				variant="outlined"
				placeholder="Search"
				size="small"
				fullWidth
				onKeyPress={(e) => {
					if (e.key === 'Enter') {
						search();
						window.location.reload();
					}
				}}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<Search />
						</InputAdornment>
					),
				}}
			/>
			{/* End of Search Field */}

			{/* Start of User Container */}
			<Box className="header_right_container">
				{/*
          If user is null, display login button.
					Else, display user image and last name.
        */}
				{auth.role <= USER.TYPE.REGISTERED ? (
					<React.Fragment>
						{/* Start of Greeting */}
						<Link
							to={{
								pathname: `/profile/${getProfileLink(auth.role)}`,
							}}
							className="header_data"
							state={{ id: auth.id }}
						>
							<Box className="header_right_user_container">
								<img
									src={auth.picture || no_picture}
									referrerPolicy="no-referrer"
									alt="new"
								/>

								<Typography variant="h5" noWrap>
									Hello,&nbsp;
									<span style={{ color: 'var(--red)' }}>{auth.firstName}!</span>
								</Typography>
							</Box>
						</Link>
						{/* End of Greeting */}

						{/* Start of Notifications */}
						{notifications.length === 0 ? (
							<NotifPopover />
						) : (
							<NotifPopover notifList={notifications} />
						)}
						{/* End of Notifications */}

						{/* Start of Owner Button */}
						{auth.role !== USER.TYPE.OWNER && auth.role !== USER.TYPE.ADMIN && (
							<Button
								onClick={handleOpenOwner}
								variant="outlined"
								sx={{ whiteSpace: 'nowrap' }}
							>
								Be an Owner
							</Button>
						)}
						{/* End of Owner Button */}

						{/* Start of Logout Button */}
						<Button
							onClick={handleLogout}
							variant="outlined"
							color="neutral"
							startIcon={<MeetingRoomIcon />}
							sx={{ whiteSpace: 'nowrap' }}
						>
							Logout
						</Button>
						{/* End of Logout Button */}

						{/* Start of Owner Form */}
						<OwnerForm
							openState={openOwner}
							handleClose={handleCloseOwner}
							handleSuccess={handleOpenSuccess}
						/>
						{/* End of Owner Form */}

						{/* Start of Success Modal */}
						<SuccessModal
							openState={openSuccess}
							handleClose={handleCloseSuccess}
							message={
								<React.Fragment>
									<b>Congratulations!</b> You&apos;re <b>one step</b> away in
									<br />
									becoming an owner! <br /> <br />
									Kindly wait for your application to be <b>reviewed.</b>
								</React.Fragment>
							}
						/>
						{/* End of Success Modal */}
					</React.Fragment>
				) : (
					<React.Fragment>
						<Button
							variant="outlined"
							onClick={handleOpenLogin}
							sx={{ whiteSpace: 'nowrap', fontWeight: 700 }}
						>
							Log in
						</Button>

						<LoginModal openState={openLogin} handleClose={handleCloseLogin} />
					</React.Fragment>
				)}
			</Box>
		</Box>
	);
}
