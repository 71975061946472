import React from 'react';
import PropTypes from 'prop-types';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { USER } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import EditModal from '../../components/modals/Edit';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FavoriteService from '../../services/favorite.service';
import {
	Grid,
	Box,
	Typography,
	Paper,
	Chip,
	Divider,
	IconButton,
	Button,
} from '@mui/material';

/**
 * Serves as the main template for the user.
 *
 * @param { Object } user Contains the user's information
 *
 * @returns { Element } The profile page of a user
 */
export default function Template({ user, children }) {
	const [isEdit, setEdit] = React.useState(false);
	const handleEditOpen = () => setEdit(true);
	const handleEditClose = () => setEdit(false);

	const navigate = useNavigate();

	// Roles in Frontend should be incremented by 1.
	const role = user.type + 1;

	// Handle the generation of the catalog.
	const downloadCatalog = async () => {
		const service = new FavoriteService();
		try {
			await service.generateFavorites(user._id);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={1}></Grid>

			{/* Start of Content */}
			<Grid item xs={3}>
				<Paper elevation={2} sx={{ px: '2rem', py: '3rem' }}>
					{/* Start of Header */}
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '1.5rem',
						}}
					>
						{/* Start of Avatar */}
						<img
							src={user.picture}
							alt="user"
							width="72rem"
							height="72rem"
							style={{ borderRadius: '100%' }}
						/>
						{/* End of Avatar */}

						{/* Start of Name and Role */}
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Typography variant="h4" sx={{ fontSize: '2rem' }}>
								{user.fullName?.firstName} {user.fullName?.lastName}
							</Typography>

							<Box>
								<Chip
									color="primary"
									sx={{ fontWeight: 700, mt: '0.5rem' }}
									label={(role === 1
										? 'Admin'
										: role === 2
										? 'Accommodation Owner'
										: 'Registered User'
									).toUpperCase()}
								/>
							</Box>
						</Box>
						{/* End of Name and Role */}
					</Box>
					{/* End of Header */}

					<Divider light sx={{ mt: '2rem' }} />

					{/* Start of Contact */}
					{/* Contact Number */}
					{role !== USER.TYPE.OWNER || (
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<Box>
								<Typography
									variant="h6"
									sx={{
										fontSize: '1rem',
										display: 'flex',
										alignItems: 'center',
										gap: '0.5rem',
										mt: '2rem',
									}}
								>
									<ContactPhoneIcon color="primary" />
									Contact Number
								</Typography>
								<Typography
									sx={{
										color: user.contactNumber ? 'var(--black)' : 'var(--gray)',
									}}
								>
									{user.contactNumber ||
										"You currently don't have a phone number."}
								</Typography>
							</Box>

							<IconButton color="secondary" onClick={handleEditOpen}>
								<ModeEditOutlineOutlinedIcon />
							</IconButton>
						</Box>
					)}

					{/* Email Address */}
					<Typography
						variant="h6"
						sx={{
							fontSize: '1rem',
							display: 'flex',
							alignItems: 'center',
							gap: '0.5rem',
							mt: '1.75rem',
						}}
					>
						<AlternateEmailIcon color="primary" />
						Email Address
					</Typography>

					<Typography>{user.email}</Typography>
					{/* End of Contact */}
				</Paper>

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: '1rem',
					}}
				>
					{/* Add Accommodation */}
					{role === USER.TYPE.OWNER && (
						<Button
							onClick={() => navigate('/add/accommodation')}
							variant="outlined"
							startIcon={<AddOutlinedIcon />}
							sx={{
								mt: '2rem',
							}}
						>
							Add Accommodation
						</Button>
					)}

					{/* Generate Catalog */}
					{(role === USER.TYPE.REGISTERED || role === USER.TYPE.OWNER) && (
						<Button
							onClick={downloadCatalog}
							variant="outlined"
							startIcon={<FileDownloadIcon />}
							sx={{
								mt: '2rem',
							}}
						>
							Generate Catalog
						</Button>
					)}
				</Box>
			</Grid>
			{/* End of Content */}

			<Grid item xs={0.5}></Grid>

			{/* Start of Children */}
			<Grid item xs={6.5}>
				{children}
			</Grid>
			{/* End of Children */}

			{/* Start of Edit Modal */}
			<EditModal
				openState={isEdit}
				handleClose={handleEditClose}
				payload={{
					id: user.id,
					role: role,
				}}
			/>
			{/* End of Edit Modal */}

			<Grid item xs={1}></Grid>
		</Grid>
	);
}

Template.propTypes = {
	user: PropTypes.object,
	children: PropTypes.element,
};
