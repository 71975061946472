import React from 'react';
import Template from './Template';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import FavoriteService from '../../services/favorite.service';
import AccommodationCard from '../../components/AccommodationCard';
import accomm_place from '../../assets/images/accomm_place.jpg';
import UserService from '../../services/user.service';
import { useLocation } from 'react-router-dom';
import { Box, Tab, Paper, Rating, Typography } from '@mui/material';

/**
 * Renders the profile of a registered user.
 *
 * @returns { Element } Profile page of the user
 */
export default function UserProfile() {
	const { state } = useLocation();
	const userId = state.id;

	// Get the favorite accommodations.
	const [favorites, setFavorites] = React.useState([]);
	const getFavorites = async (userId) => {
		const service = new FavoriteService();
		try {
			const res = await service.getFavorites(userId);
			setFavorites(res.data);
		} catch (err) {
			console.log(err);
		}
	};

	// Get the user's reviews.
	const [reviews, setReviews] = React.useState([]);
	const getReviews = async (userId) => {
		const service = new UserService();
		try {
			const res = await service.userReviews(userId);
			setReviews(res);
		} catch (err) {
			console.log(err);
		}
	};

	// Get the user's details.
	const [userDetails, setUserDetails] = React.useState({});
	const getUser = async () => {
		const service = new UserService();
		try {
			const res = await service.userDetails(userId);
			setUserDetails(res);
		} catch (err) {
			console.log(err);
		}
	};

	React.useEffect(() => {
		getFavorites(userId);
		getReviews(userId);
		getUser();
	}, []);

	// Handle the change of tabs.
	const [tab, setTab] = React.useState(1);
	const handleChange = (event, newTab) => {
		setTab(newTab);
	};

	return (
		<Template user={userDetails}>
			{/* Start of Tabs */}
			<TabContext value={tab}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleChange}>
						<Tab label="Favorites" value={1} />
						<Tab label="User Reviews" value={2} />
					</TabList>
				</Box>

				{/* Start of Favorites */}
				<TabPanel value={1}>
					<Box
						sx={{
							mt: '2rem',
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							gap: '3rem',
							mb: '6rem',
						}}
					>
						{favorites.map((entry, i) => {
							return (
								!entry.isArchived && (
									<AccommodationCard
										key={i}
										thumbnail={entry.pictures[0] || accomm_place}
										name={entry.accommodationName}
										location={entry.location}
										type={entry.type}
										minPrice={entry.price[0]}
										rating={entry.rating || 'N/A'}
										id={entry._id}
										ownerId={entry.ownerId}
									/>
								)
							);
						})}
					</Box>
				</TabPanel>
				{/* End of Favorites */}

				{/* Start of User Reviews */}
				<TabPanel value={2}>
					<Box
						sx={{
							mt: '2rem',
							display: 'flex',
							flexDirection: 'column',
							flexWrap: 'wrap',
							gap: '1.5rem',
							mb: '6rem',
						}}
					>
						{reviews.map((entry, i) => {
							return (
								<Paper key={i} sx={{ width: '50%', py: '1.5rem', px: '2rem' }}>
									{/* Name and Rating */}
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<Typography variant="h5" sx={{ fontSize: '1.4rem' }}>
											{entry.userName}
										</Typography>
										<Rating name="read-only" value={entry.rating} readOnly />
									</Box>

									{/* Date */}
									<Typography
										variant="body1"
										sx={{
											textAlign: 'justify',
											color: 'var(--gray)',
										}}
									>
										Posted last {new Date(entry.createdAt).toDateString()}
									</Typography>

									{/* Description */}
									<Typography
										variant="body1"
										sx={{ mt: '1rem', textAlign: 'justify' }}
									>
										{entry.desc}
									</Typography>
								</Paper>
							);
						})}
					</Box>
				</TabPanel>
				{/* End of User Reviews */}
			</TabContext>
			{/* End of Tabs */}
		</Template>
	);
}
