import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
	Button,
	Typography,
	Container,
	Grid,
	Box,
	Divider,
	Stack,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	TextField,
	Rating,
	CircularProgress,
} from '@mui/material';
import { Carousel } from 'react-carousel-minimal';
import {
	Star,
	CheckCircleOutline as CheckIcon,
	// HighlightOff as CrossIcon, // TODO: Include excluded amenities.
	// Favorite as FavoriteIcon,
	Home as HomeIcon,
	Place as LocIcon,
	Description as MinStayIcon,
	Payments as PriceIcon,
	PeopleAlt as SuitIcon,
	Person as TenantsIcon,
	Archive as ArchiveIcon,
	Unarchive as UnarchiveIcon,
	Delete as DeleteIcon,
} from '@mui/icons-material';
import { PropTypes } from 'prop-types';
import ReviewAccommProfile from '../../components/review/ReviewAccommProfile';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import ShowReviewModal from '../../components/modals/ShowReview';
import Report from '../../components/modals/Report';
import DeleteAccommModal from '../../components/modals/DeleteAccomm';
import ReviewService from '../../services/review.service';
import useAuth from '../../hooks/useAuth';
import AccommService from '../../services/accommodation.service';
import { USER } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import accomm_place from '../../assets/images/accomm_place.jpg';
import RateReviewIcon from '@mui/icons-material/RateReview';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReportIcon from '@mui/icons-material/Report';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

function BootstrapDialogTitle(props) {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
}

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

/**
 * Renders a `profile` card containing information
 * about the accommodation place.
 *
 * @returns { Element } The layout of the accommodation profile page
 */
export default function AccommodationProfile() {
	// To transition states between buttons.
	// const [faveClicked, setFaveClicked] = useState(false);
	const [open, setOpen] = useState(false);
	const [accommodation, setAccommodation] = useState({});
	const [isDataFetched, setIsDataFetched] = useState(false);
	const setDataFetchedTrue = () => setIsDataFetched(true);
	const setDataFetchedFalse = () => setIsDataFetched(false);
	const [reviewForm, setReviewForm] = useState({
		rating: 0,
		desc: '',
	});

	const [averageRating, setAverageRating] = useState(null);
	const [accommImages, setAccommImages] = useState([]);

	// Get the ID from the profile.
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const accommId = urlParams.get('id');

	// Get ID of currently logged in user
	const { auth } = useAuth();

	// Opens delete modal
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const handleOpenDeleteModal = () => setOpenDeleteModal(true);
	const handleCloseDeleteModal = () => setOpenDeleteModal(false);

	// Variables to store reviews data
	const [reviewDetails, setReviewDetails] = useState([]);

	const [openReview, setOpenReview] = useState(false);
	const handleOpenReview = () => setOpenReview(true);
	const handleCloseReview = () => setOpenReview(false);

	// Handle reports.
	const [openReport, setOpenReport] = useState(false);
	const handleOpenReport = () => setOpenReport(true);
	const handleCloseReport = () => setOpenReport(false);

	const navigate = useNavigate();

	const getReviews = async (accomId) => {
		const service = new ReviewService();
		try {
			const reviewRes = await service.viewAllReviews(accomId);
			if (reviewRes === undefined) throw new Error();
			setReviewDetails(reviewRes.reviews);
			setAverageRating(reviewRes.average);
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * TODO: Add this function on the 'onClick' handler
	 *       of the Edit button.
	 */
	// const editAccomm = () => {
	// 	navigate(`/edit/accommodation/?id=${accommId}`);
	// };

	useEffect(() => {
		// Fetch reviews
		getReviews(accommId);

		// Fetch accommodation details
		const fetchData = async () => {
			const service = new AccommService();

			try {
				const accommData = await service.viewAccommodation(accommId);

				// Edit type property
				if (accommData.type === 0) {
					accommData.type = 'hotel';
				} else if (accommData.type === 1) {
					accommData.type = 'apartment';
				} else if (accommData.type === 2) {
					accommData.type = 'bed space';
				} else if (accommData.type === 3) {
					accommData.type = 'dormitory';
				} else if (accommData.type === 4) {
					accommData.type = 'transient';
				}

				// Edit tenants property
				if (accommData.tenants === 0) {
					accommData.tenants = 'male';
				} else if (accommData.tenants === 1) {
					accommData.tenants = 'female';
				} else if (accommData.tenants === 2) {
					accommData.tenants = 'coed';
				}

				setAccommodation(accommData);

				// Set accommodation images for the carousel
				const images = accommData.pictures.map((pictureLink) => ({
					image: pictureLink,
				}));
				setAccommImages(images);

				setDataFetchedTrue();
			} catch (error) {
				console.error(error);
			}
		};

		if (!isDataFetched) {
			fetchData();
		}
	}, [isDataFetched, reviewDetails]);

	const handleLeaveReview = () => {
		setOpen(true);
	};

	// const handleViewReview = () => {
	// 	setOpen(true);
	// };

	const handleClose = () => {
		setOpen(false);
	};

	const handleProceed = async () => {
		const service = new ReviewService();
		try {
			// Additional form details
			reviewForm.userId = auth && auth.id;
			reviewForm.accommodationId = accommId;
			reviewForm.isAnonymous = false;
			reviewForm.isAccepted = false;

			const res = await service.addReview(reviewForm);
			if (res.success) {
				toast.success(res.message, { duration: 5000 });
			} else {
				toast.error(res.message, { duration: 5000 });
			}

			setOpen(false);
			setDataFetchedFalse();

			// Clear form
			setReviewForm({
				rating: 0,
				desc: '',
			});

			// Close the modal.
			handleClose();
		} catch (err) {
			console.log(err);
		}
	};

	const handleChange = (e) => {
		setReviewForm({
			...reviewForm,
			[e.target.name]: e.target.value,
		});

		if (e.target.name == 'rating') {
			const ratingValue = parseFloat(e.target.value);
			setReviewForm({
				...reviewForm,
				[e.target.name]: ratingValue,
			});
		}
	};

	const handleArchive = async () => {
		const service = new AccommService();
		try {
			const res = await service.archiveAccommodation(accommId);
			if (res.success) {
				toast.success(res.message, { duration: 5000 });
				setDataFetchedFalse;
			} else {
				toast.error(res.message, { duration: 5000 });
			}

			window.location.reload();
		} catch (err) {
			console.log(err);
		}
	};

	const handleDelete = async () => {
		const service = new AccommService();
		try {
			const res = await service.deleteAccommodation(accommId);
			if (res.success) {
				setDataFetchedFalse;
				handleClose;
				toast.success(res.message, { duration: 5000 });
				navigate('/');
			} else {
				toast.error(res.message, { duration: 5000 });
			}
		} catch (err) {
			console.log(err);
		}
	};

	return isDataFetched ? (
		<Container>
			{/* Title of page */}
			<Typography
				variant="h2"
				sx={{
					marginBottom: 2,
					display: 'flex',
					justifyContent: 'left',
				}}
			>
				{accommodation.accommodationName}
			</Typography>

			{/* Start of Rating and Number of Reviews */}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'row',
					}}
				>
					<Star sx={{ color: 'var(--gray)', paddingRight: 0.5 }} />
					<Typography
						variant="body1"
						color="var(--gray)"
						sx={{
							fontWeight: 'medium',
							marginBottom: 3,
							display: 'flex',
						}}
					>
						{averageRating} • {reviewDetails.length}{' '}
						{reviewDetails.length == 1 ? 'review' : 'reviews'}
					</Typography>
				</Box>

				<Box
					sx={{
						display: 'flex',
						justifyContent: 'row',
					}}
				>
					{auth.id === accommodation.ownerId &&
						auth.role === USER.TYPE.OWNER && (
							<React.Fragment>
								<div
									style={{ cursor: 'pointer' }}
									onClick={handleOpenDeleteModal}
								>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'row',
										}}
									>
										<DeleteIcon
											sx={{ color: 'var(--gray)', paddingRight: 0.5 }}
										/>
										<Typography
											variant="body1"
											sx={{
												justifyContent: 'left',
												textDecoration: 'underline',
												color: 'var(--gray)',
												paddingRight: 2,
											}}
										>
											Delete
										</Typography>
									</Box>
								</div>

								<div style={{ cursor: 'pointer' }} onClick={handleArchive}>
									{accommodation.isArchived ? (
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'row',
											}}
										>
											<UnarchiveIcon
												sx={{ color: 'var(--gray)', paddingRight: 0.5 }}
											/>
											<Typography
												variant="body1"
												sx={{
													justifyContent: 'left',
													textDecoration: 'underline',
													color: 'var(--gray)',
													paddingRight: 2,
												}}
											>
												Unarchive
											</Typography>
										</Box>
									) : (
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'row',
											}}
										>
											<ArchiveIcon
												sx={{ color: 'var(--gray)', paddingRight: 0.5 }}
											/>
											<Typography
												variant="body1"
												sx={{
													justifyContent: 'left',
													textDecoration: 'underline',
													color: 'var(--gray)',
													paddingRight: 2,
												}}
											>
												Archive
											</Typography>
										</Box>
									)}
								</div>
							</React.Fragment>
						)}

					{auth.id !== accommodation.ownerId &&
						(auth.role === USER.TYPE.ADMIN ||
							auth.role === USER.TYPE.OWNER ||
							auth.role === USER.TYPE.REGISTERED) && (
							<div style={{ cursor: 'pointer' }} onClick={handleOpenReport}>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'row',
									}}
								>
									<ReportIcon
										sx={{ color: 'var(--gray)', paddingRight: 0.5 }}
									/>
									<Typography
										variant="body1"
										sx={{
											justifyContent: 'left',
											textDecoration: 'underline',
											color: 'var(--gray)',
											paddingRight: 2,
										}}
									>
										Report
									</Typography>
								</Box>
							</div>
						)}

					<DeleteAccommModal
						accomName={accommodation.accommodationName}
						openState={openDeleteModal}
						handleClose={handleCloseDeleteModal}
						handleOpenSubmit={handleDelete}
					/>

					<Report
						openState={openReport}
						handleClose={handleCloseReport}
						accommId={accommId}
						accomOwnerId={accommodation.ownerId}
					/>

					{/* TODO: Bring this back later on. */}
					{/* <div
								style={{ cursor: 'pointer' }}
								onClick={() => setFaveClicked(!faveClicked)}
							>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'row',
									}}
								>
									<FavoriteIcon
										sx={{
											paddingRight: 0.5,
											color: faveClicked ? 'var(--red)' : 'var(--gray)',
										}}
									/>
									<Typography
										variant="body1"
										color={faveClicked ? 'var(--red)' : 'var(--gray)'}
										sx={{
											justifyContent: 'left',
											textDecoration: 'underline',
										}}
									>
										{faveClicked ? 'Saved' : 'Save'}
									</Typography>
								</Box>
							</div> */}
				</Box>
			</Box>
			{/* End of Rating and Number of Reviews */}

			{/* Start of Amenities */}
			<Grid container columns={12}>
				<Grid
					item
					xs={3}
					height="500px"
					alignItems="center"
					display="flex"
					flexDirection="column"
				>
					{/* Amenities */}
					<Box
						sx={{
							display: 'block',
						}}
					>
						<Typography
							variant="h4"
							sx={{
								marginTop: 6,
								marginBottom: 2,
								display: 'block',
								justifyContent: 'left',
							}}
						>
							Amenities
						</Typography>
						<Box
							sx={{
								display: 'block',
								paddingLeft: 3,
							}}
						>
							{accommodation.amenities.map((amenity) => {
								return (
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'left',
										}}
										key={amenity}
									>
										<CheckIcon
											sx={{ color: 'var(--green)', paddingRight: 1 }}
										/>
										<Typography
											variant="body1"
											color="var(--gray)"
											sx={{
												fontWeight: 'medium',
												marginBottom: 2,
												justifyContent: 'left',
											}}
										>
											{amenity.charAt(0).toUpperCase() +
												amenity.slice(1).toLowerCase()}
										</Typography>
									</Box>
								);
							})}

							{/* TODO: Implement integration for excluded amenity */}
							{/* <Box
										sx={{
											display: 'flex',
											justifyContent: 'left',
										}}
									>
										<CrossIcon sx={{ color: 'var(--red)', paddingRight: 1 }} />
										<Typography
											variant="body1"
											color="var(--gray)"
											sx={{
												fontWeight: 'medium',
												marginBottom: 2,
												justifyContent: 'left',
											}}
										>
											Placeholder for excluded amenity
										</Typography>
									</Box> */}
						</Box>
					</Box>
				</Grid>
				{/* End of Amenities */}

				{/* Start of Carousel */}
				<Grid item xs={9}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							marginBottom: 8,
							padding: 0,
						}}
					>
						<Carousel
							data={accommImages || [accomm_place]}
							time={2000}
							width="939px"
							height="500px"
							radius="4px"
							slideNumber={false}
							automatic={true}
							dots={false}
							pauseIconSize="0px"
							slideBackgroundColor="darkgrey"
							slideImageFit="cover"
							thumbnails={false}
							style={{}}
						/>
					</Box>
				</Grid>
			</Grid>
			{/* End of Carousel */}

			{/* Start of Accommodation Details */}
			<Grid container columns={12} spacing={1}>
				<Stack spacing={5} flex="1 1 0" marginRight={5} paddingTop={3.5}>
					<Divider />
					<Grid container columns={12} spacing={1} marginBottom={8}>
						<Grid item xs={8}>
							{/* Start of first column of details (type, location, minimum stay) */}
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'left',
								}}
							>
								{/* Start of Type */}
								<HomeIcon
									sx={{
										fontSize: 30,
										paddingRight: 0.5,
										color: 'var(--red)',
									}}
								/>
								{/* Title */}
								<Typography
									variant="h6"
									sx={{
										justifyContent: 'left',
									}}
								>
									{accommodation.type.charAt(0).toUpperCase() +
										accommodation.type.slice(1).toLowerCase()}
								</Typography>
							</Box>

							{/* Caption */}
							<Typography
								variant="body1"
								sx={{
									marginBottom: 2,
									marginLeft: 4.3,
									justifyContent: 'left',
								}}
							>
								Type
							</Typography>
							{/* End of Type */}

							{/* Start of Location */}
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'left',
								}}
							>
								<LocIcon
									sx={{
										paddingRight: 0.5,
										fontSize: 30,
										color: 'var(--red)',
									}}
								/>
								{/* Title */}
								<Typography
									variant="h6"
									sx={{
										justifyContent: 'left',
									}}
								>
									{accommodation.location}
								</Typography>
							</Box>

							{/* Caption */}
							<Typography
								variant="body1"
								sx={{
									marginBottom: 2,
									marginLeft: 4.3,
									justifyContent: 'left',
								}}
							>
								{accommodation.completeAddress}
							</Typography>
							{/* End of Locatioin */}

							{/* Start of Minimum Stay */}
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'left',
								}}
							>
								<MinStayIcon
									sx={{
										paddingRight: 0.5,
										fontSize: 30,
										color: 'var(--red)',
									}}
								/>
								{/* Title */}
								<Typography
									variant="h6"
									sx={{
										justifyContent: 'left',
									}}
								>
									{accommodation.minimumStay} months
								</Typography>
							</Box>

							{/* Caption */}
							<Typography
								variant="body1"
								sx={{
									marginBottom: 2,
									marginLeft: 4.3,
									justifyContent: 'left',
								}}
							>
								Minimum stay
							</Typography>
							{/* End of Minimum Stay */}
							{/* End of first column of details */}
						</Grid>

						{/* Start of second column of details (price range, suitability, tenants allowed) */}
						<Grid item xs={4}>
							{/* Start of Price Range */}
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'left',
								}}
							>
								<PriceIcon
									sx={{
										paddingRight: 0.5,
										fontSize: 30,
										color: 'var(--green)',
									}}
								/>
								{/* Title */}
								<Typography
									variant="h6"
									sx={{
										justifyContent: 'left',
									}}
								>
									PHP {accommodation.price[0]} - {accommodation.price[1]}
								</Typography>
							</Box>

							{/* Caption */}
							<Typography
								variant="body1"
								sx={{
									marginBottom: 2,
									marginLeft: 4.3,
									justifyContent: 'left',
								}}
							>
								Price range
							</Typography>
							{/* End of Price Range */}

							{/* Start of Suitability */}
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'left',
								}}
							>
								<SuitIcon
									sx={{
										paddingRight: 0.5,
										fontSize: 30,
										color: 'var(--red)',
									}}
								/>
								{/* Title */}
								<Typography
									variant="h6"
									sx={{
										justifyContent: 'left',
									}}
								>
									{accommodation.pax}{' '}
									{accommodation.pax > 1 ? 'Persons' : 'Person'}
								</Typography>
							</Box>

							{/* Caption */}
							<Typography
								variant="body1"
								sx={{
									marginBottom: 2,
									marginLeft: 4.3,
									justifyContent: 'left',
								}}
							>
								Suitability
							</Typography>
							{/* End of Suitability */}

							{/* Start of Tenants */}
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'left',
								}}
							>
								<TenantsIcon
									sx={{
										paddingRight: 0.5,
										fontSize: 30,
										color: 'var(--red)',
									}}
								/>
								{/* Title */}
								<Typography
									variant="h6"
									sx={{
										justifyContent: 'left',
									}}
								>
									{accommodation.tenants}
								</Typography>
							</Box>

							{/* Caption */}
							<Typography
								variant="body1"
								sx={{
									marginBottom: 2,
									marginLeft: 4.3,
									justifyContent: 'left',
								}}
							>
								Tenants allowed
							</Typography>
						</Grid>
						{/* End of Suitability */}
						{/* End of second column of details */}
					</Grid>

					<Divider marginBottom={8} />

					{/* Start of accommodation description */}
					<Typography
						variant="body1"
						sx={{
							marginTop: 8,
							justifyContent: 'space-between',
						}}
					>
						{accommodation.description}
					</Typography>
					{/* End of accommodation description */}
				</Stack>

				{/* Start of reviews */}
				<Grid item xs={4}>
					<Typography
						variant="h4"
						sx={{
							marginBottom: 2,
							display: 'block',
							justifyContent: 'left',
						}}
					>
						Reviews
					</Typography>

					<ReviewAccommProfile
						review={reviewDetails.slice(0, 3)}
						isProfile={false}
					/>

					{/* Show all reviews and button */}
					{/* If userId is same as the ownerId of the current accommodation, remove the leave a review option */}
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<ShowReviewModal
							review={reviewDetails}
							openState={openReview}
							handleClose={handleCloseReview}
						/>

						{
							<React.Fragment>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										gap: '1rem',
										mt: '1.25rem',
									}}
								>
									<Button
										onClick={handleOpenReview}
										variant="outlined"
										color="primary"
										startIcon={<VisibilityIcon />}
									>
										View Reviews
									</Button>

									{auth.id != accommodation.ownerId &&
										(auth.role === USER.TYPE.OWNER ||
											auth.role === USER.TYPE.REGISTERED) && (
											<Button
												onClick={handleLeaveReview}
												variant="outlined"
												color="neutral"
												startIcon={<RateReviewIcon />}
											>
												Leave a Review
											</Button>
										)}
								</Box>

								<BootstrapDialog
									onClose={handleClose}
									aria-labelledby="customized-dialog-title"
									open={open}
								>
									<div style={{ padding: 10, width: 500 }}>
										<Typography
											variant="h5"
											sx={{
												paddingTop: 3,
												justifyContent: 'center',
												textAlign: 'center',
											}}
										>
											Leave a Review
										</Typography>
										<DialogContent>
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'left',
													alignItems: 'center',
												}}
											>
												<Typography
													variant="h6"
													sx={{
														paddingBottom: 1,
														paddingTop: 1,
														paddingRight: 2,
														justifyContent: 'left',
													}}
												>
													Rating
												</Typography>
												<Rating
													name="rating"
													value={reviewForm.rating}
													onChange={handleChange}
												/>
											</Box>

											<Typography
												variant="h6"
												sx={{
													paddingBottom: 1,
													justifyContent: 'left',
												}}
											>
												Care to share more?
											</Typography>
											<TextField
												placeholder="Add a review about the place"
												name="desc"
												value={reviewForm.desc}
												onChange={handleChange}
												multiline
												rows={6}
												fullWidth
											/>
										</DialogContent>
										<DialogActions>
											<Grid
												container
												spacing={2}
												columns={12}
												sx={{
													flexGrow: 1,
													paddingLeft: 3,
													paddingRight: 3,
													paddingTop: 1,
													paddingBottom: 2,
												}}
												justifyContent="space-between"
												display="flex"
											>
												<Grid xs={7}>
													<Button
														onClick={handleClose}
														variant="outlined"
														sx={{ whiteSpace: 'nowrap', width: 225 }}
													>
														<b>Cancel</b>
													</Button>
												</Grid>
												<Grid xs={5}>
													<Button
														onClick={handleProceed}
														variant="contained"
														sx={{
															whiteSpace: 'nowrap',
															width: 225,
															marginLeft: -2.5,
														}}
													>
														<b style={{ color: 'var(--white)' }}>Proceed</b>
													</Button>
												</Grid>
											</Grid>
										</DialogActions>
									</div>
								</BootstrapDialog>
							</React.Fragment>
						}
					</Box>
					{/* End of rating */}
				</Grid>
			</Grid>

			{/* Space Allowance */}
			<Box sx={{ marginBottom: 16 }}></Box>
		</Container>
	) : (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			<CircularProgress
				size={40}
				sx={{ color: 'gray' }}
				style={{ paddingTop: '100px', paddingBottom: '100px' }}
			/>
		</div>
	);
}

AccommodationProfile.propTypes = {
	accommId: PropTypes.string,
};
